import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { CareerPosition } from '@content/career/career-positions/career-positions.types';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { AppPath } from '@shared/routes/app-routing.enum';
import { CareerDetailsScrollDirective } from '../career-details-scroll.directive';
import { CareerDetailsAssetPath } from '../career-details.path';
import { CareerDetailsService } from '../career-details.service';
import { CareerDetailsTitles } from '../career-details.titles';

@Component({
    selector: 'jsdaddy-career-details-header',
    standalone: true,
    templateUrl: './career-details-header.component.html',
    styleUrl: './career-details-header.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        RouterLinkWithHref,
        AssetPipe,
        CareerDetailsScrollDirective,
        NgOptimizedImage,
    ],
})
export class CareerDetailsHeaderComponent {
    public career = input.required<CareerPosition | undefined>();
    public readonly assetPathArrowButton = SharedAssetPath.ARROW_BUTTON;
    public readonly assetPath = CareerDetailsAssetPath.HEADER;
    public readonly careerRoutePath = `/${AppPath.CAREER}`;
    public readonly header = CareerDetailsTitles.PROJECT_DETAILS;

    private readonly careerDetailsService = inject(CareerDetailsService);

    public section = this.careerDetailsService.getSection(CareerDetailsTitles.PROJECT_DETAILS);
    public onSectionChange(sectionId: string): void {
        this.careerDetailsService.changeSection(sectionId);
    }
}
