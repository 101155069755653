import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    ViewEncapsulation,
    inject,
    signal,
    DestroyRef,
} from '@angular/core';
import { ResizeService } from '@shared/resize/resize.service';
import { ScreenSize } from '@shared/screen-size/screen-size.enum';
import { ShowCasesListComponent } from '@shared/show-cases-list/show-cases-list.component';
import { ShowCasesService } from '@shared/show-cases/show-cases.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-show-cases',
    standalone: true,
    imports: [ShowCasesListComponent],
    providers: [ShowCasesService],
    templateUrl: './show-cases.component.html',
    styleUrl: './show-cases.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowCasesComponent implements OnInit {
    private readonly resizeService = inject(ResizeService);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly document = inject(DOCUMENT);
    private readonly showCasesService = inject(ShowCasesService);

    public readonly showCases = toSignal(this.showCasesService.getShowCases());
    public readonly screenSizeMobile = ScreenSize.MOBILE;
    public innerWidth = signal(0);
    private readonly destroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const body: HTMLBodyElement | null = this.document.querySelector('body');
        if (body) {
            this.resizeService
                .createResizeSequence(body)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((width: number) => {
                    this.innerWidth.set(width);
                });
        }
    }
}
