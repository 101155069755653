import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input,
} from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ChipComponent } from '@libraries/chip/chip.component';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { BreadcrumbsComponent } from '@shared/breadcrumbs/breadcrumbs.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ShowCaseIsMobilePipe } from '@shared/show-case-mobile/show-case-is-mobile.pipe';
import { ImageWithFrameComponent } from '@shared/show-cases/image-with-frame/image-with-frame.component';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { CaseAssetPath } from '../case.path';
import { CaseHeaderShopComponent } from './case-header-shop/case-header-shop.component';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-case-header[showCase]',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        RouterLinkWithHref,
        AssetPipe,
        ImageUrlPipe,
        ShowCaseIsMobilePipe,
        SectionWrapperComponent,
        HeaderTitleComponent,
        ChipComponent,
        ImageWithFrameComponent,
        BreadcrumbsComponent,
        CaseHeaderShopComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    templateUrl: './case-header.component.html',
    styleUrl: './case-header.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseHeaderComponent {
    public showCase = input<ShowCase | undefined>();
    public assetPath = CaseAssetPath.HEADER;
    public assetPathShared = SharedAssetPath.ROOT;
    public assetPathSharedShowCases = SharedAssetPath.SHOW_CASES;

    public readonly router = inject(Router);
}
