import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { FormSendingDialogService } from './form-sending-dialog.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-form-sending-dialog',
    standalone: true,
    imports: [NgTemplateOutlet, AssetPipe, ButtonComponent, NgOptimizedImage],
    templateUrl: './form-sending-dialog.component.html',
    styleUrl: './form-sending-dialog.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSendingDialogComponent {
    public readonly assetPath = 'shared/contact-form';
    public readonly buttonType = ButtonType.PrimaryYellowNoShevron;
    public readonly loadingType: FormSendingDialogType = FormSendingDialogType.LOADING;
    public readonly errorType: FormSendingDialogType = FormSendingDialogType.ERROR;
    public readonly successType: FormSendingDialogType = FormSendingDialogType.SUCCESS;

    private readonly service = inject(FormSendingDialogService);

    public readonly type = toSignal(this.service.type$);

    public isOpened = toSignal(this.service.isOpened$);

    public open(): void {
        this.service.open();
    }

    public close(): void {
        this.service.close();
    }
}

export enum FormSendingDialogType {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
}
