@if (!link()) {
    <button [class]="[type(), 'text'].join(' ')" [style.justify-content]="justifyContent()">
        {{ title() }}
    </button>
} @else if (link()! | button) {
    <a
        [attr.aria-label]="title() | anchorLabel"
        target="_blank"
        [class]="[type(), 'text'].join(' ')"
        [href]="link()">
        {{ title() }}
    </a>
} @else if (!(link()! | button)) {
    <a
        [attr.aria-label]="title() | anchorLabel"
        [class]="[type(), 'text'].join(' ')"
        [routerLink]="link()">
        {{ title() }}
    </a>
}
