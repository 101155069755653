import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ImageUrlPipe } from './../../image-url/image-url.pipe';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-photo-card[image][linkedin]',
    standalone: true,
    imports: [ImageUrlPipe, AssetPipe, NgOptimizedImage],
    templateUrl: './photo-card.component.html',
    styleUrl: './photo-card.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoCardComponent {
    public image = input.required<string>();
    public linkedin = input.required<string>();
}
