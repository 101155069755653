<div class="input-wrapper" [class]="control | input: true" [class.dark]="isDark()">
    @if (!isTextarea()) {
        <input
            [formControl]="control"
            [class.dark]="isDark()"
            type="text"
            [jsdaddyAutofocus]="autoFocus()"
            name="input"
            id="{{ uniqueId }}"
            [placeholder]="placeholder()"
            (keyup.enter)="onLabelClick()"
            class="span" />
    } @else {
        <textarea
            [formControl]="control"
            [class.dark]="isDark()"
            type="text"
            name="input"
            id="{{ uniqueId }}"
            [jsdaddyAutofocus]="autoFocus()"
            [placeholder]="placeholder()"
            (keyup.enter)="onLabelClick()"
            class="span"></textarea>
    }

    <label aria-hidden="true" (click)="onLabelClick()" for="{{ uniqueId }}">
        @if ((control | input) || (label() | uppercase); as l) {
            {{ l }}
        } @else {
            @if (control.touched) {
                <img
                    alt="Done"
                    width="15"
                    height="11"
                    [ngSrc]="'done-yellow' | asset: additionalPath" />
            }
        }
    </label>
</div>
