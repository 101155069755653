// type-coverage:ignore-next-line
import { inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { catchError, EMPTY, from, Observable, switchMap } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { RECAPTCHA_STATE_KEY } from '@shared/recaptcha/recaptcha.token';

declare const grecaptcha: {
    ready: (cb: () => void) => void;
    execute: (token: string, action: { action: string }) => string;
};

@Injectable()
export class RecaptchaService {
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly transferState = inject(TransferState);

    public grecaptchaKey = this.transferState.get<string>(RECAPTCHA_STATE_KEY, '');

    public recaptchaReady(): Observable<boolean> {
        return new Observable((subscriber) => {
            grecaptcha.ready(() => {
                subscriber.next(true);
                subscriber.complete();
            });
        });
    }

    public recaptcha(action: string): Observable<string> {
        if (isPlatformServer(this.platformId)) {
            return EMPTY;
        }
        return this.recaptchaReady().pipe(
            switchMap(() => {
                return from(grecaptcha.execute(this.grecaptchaKey ?? '', { action }));
            }),
            catchError(() => {
                return EMPTY;
            })
        );
    }
}
