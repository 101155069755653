@if (content()) {
    <jsdaddy-header-image
        [title]="content()?.title"
        subtitle="OUR SERVICES"
        [asset]="'header' | asset: assetPath : true" />
    <jsdaddy-section-wrapper> <jsdaddy-service-technologies /></jsdaddy-section-wrapper>

    <jsdaddy-service-projects ngSkipHydration>
        <div right class="column right-side pos-rel">
            <span class="h3-text">{{ content()?.title }}</span>
            <div class="row chips">
                @for (chip of content()?.chips || []; track chip) {
                    <jsdaddy-chip class="row" [chip]="chip" />
                }
            </div>
            <span class="span" [innerHTML]="content()?.description | indent"></span>
            <jsdaddy-button
                [link]="contactUsPath"
                [type]="primaryYellow"
                title="Want to Discuss!" />
        </div>
    </jsdaddy-service-projects>

    <jsdaddy-section-wrapper><jsdaddy-development-process /></jsdaddy-section-wrapper>

    <jsdaddy-section-wrapper>
        <jsdaddy-our-clients-say />
    </jsdaddy-section-wrapper>
}
