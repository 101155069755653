import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'openSource',
    standalone: true,
    pure: true,
})
export class OpenSourcePipe implements PipeTransform {
    public transform(value: number): string {
        const lessThan1k = 999;
        const lessThan1b = 999999;
        const billion = 1000000;
        const thousand = 1000;
        if (value > lessThan1k && value < billion) {
            return `${Math.floor(value / thousand)}k`;
        } else if (value > lessThan1b) {
            return `${(value / billion).toFixed(1).replace('.', ',')}m`;
        } else {
            return `${value}`;
        }
    }
}
