import { DOCUMENT, isPlatformServer, NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    effect,
    ElementRef,
    inject,
    input,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { ResizeService } from '@shared/resize/resize.service';
import { ScreenSize } from '@shared/screen-size/screen-size.enum';
import { tap } from 'rxjs';

@Component({
    selector: 'jsdaddy-header-title[title]',
    standalone: true,
    imports: [AssetPipe, NgOptimizedImage],
    templateUrl: './header-title.component.html',
    styleUrl: './header-title.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTitleComponent implements AfterViewInit {
    public title = input.required<string | undefined>();
    public subtitle = input<string | undefined>();
    public black = input(false);
    public needStar = input(false);
    public isSubtitleMoreThanText = input<boolean>(false);
    public isTitleRightSide = input<boolean>(false);
    public left = -40;
    public subtitleWidth = 0;
    public textWidth = 0;
    public assetPath = SharedAssetPath.ROOT;
    public textElement = viewChild<string, ElementRef<HTMLDivElement>>('textElement', {
        read: ElementRef,
    });
    public subtitleElement = viewChild<string, ElementRef<HTMLSpanElement>>('subtitleElement', {
        read: ElementRef,
    });

    private readonly resizeService = inject(ResizeService);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly document = inject(DOCUMENT);

    public constructor() {
        effect(() => {
            if (this.isSubtitleMoreThanText()) {
                this.subtitleWidth = ScreenSize.DESKTOP;
            } else {
                this.textWidth = ScreenSize.DESKTOP;
            }
        });
    }

    public ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const body: HTMLBodyElement | null = this.document.querySelector('body');
        if (body) {
            this.resizeService
                .createResizeSequence(body)
                .pipe(
                    tap(() => {
                        const element = this.textElement()?.nativeElement;
                        if (!element) {
                            return;
                        }
                        const textWidth = this.measureTextWidth(element);
                        const lineHeight = parseInt(getComputedStyle(element).lineHeight);
                        const actualHeight = element?.offsetHeight;
                        const expectedHeight = lineHeight * 1.5;
                        const fault = 4;
                        if (!this.isSubtitleMoreThanText()) {
                            this.subtitleWidth = textWidth + fault;
                        } else {
                            if (element) {
                                const subtitleWidth = this.measureTextWidth(element);
                                this.textWidth = subtitleWidth + fault;
                            }
                        }
                        if (actualHeight > expectedHeight) {
                            this.left = textWidth;
                        }
                        this.cdr.detectChanges();
                    })
                )
                .subscribe();
        }
    }

    private measureTextWidth(element: HTMLElement): number {
        if (isPlatformServer(this.platformId)) {
            return 0;
        }
        const canvas = this.document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            return 0;
        }
        context.font = window.getComputedStyle(element).getPropertyValue('font');
        return element.getBoundingClientRect().width;
    }
}
