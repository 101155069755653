<div
    [class]="styleList"
    class="section-wrapper"
    [style.overflowY]="overflowYStyle()"
    [style.overflowX]="overflowXStyle()"
    [style.min-height]="minHeight()"
    [style.max-height]="maxHeight()">
    <div>
        <ng-content />
    </div>
</div>
