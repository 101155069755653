<div class="sidebar" [class.last-section]="isAbsolutePosition()">
    <span class="h3-text sidebar-title">Table of Contents</span>
    @for (section of sections; track section.title) {
        <div
            [tabindex]="0"
            (click)="scrollTo(section.id)"
            (keydown)="handleKeydown($event, section.id)"
            class="pointer text sidebar-tile"
            [class.active]="section.id === currentSection()">
            {{ section.title }}
        </div>
    }
</div>
