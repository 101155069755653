import { inject, Pipe, PipeTransform } from '@angular/core';
import { MenuService } from './menu.service';

@Pipe({
    name: 'widthServicePage',
    standalone: true,
})
export class WidthServicePagePipe implements PipeTransform {
    public readonly menuService = inject(MenuService);

    public transform(value: number | null, innerWidth: number): number {
        return this.menuService.getWidthServicePage(value, innerWidth);
    }
}
