@if (!link()) {
    <span class="text orange-yellow">{{ title() }}</span>
} @else if (link()! | button) {
    <a
        [attr.aria-label]="title() | anchorLabel"
        target="_blank"
        class="text orange-yellow"
        [href]="link()">
        {{ title() }}
    </a>
} @else if (!(link()! | button)) {
    <a [attr.aria-label]="title() | anchorLabel" class="text orange-yellow" [routerLink]="link()">
        {{ title() }}
    </a>
}
