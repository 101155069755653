@if (content()) {
    <jsdaddy-case-header [showCase]="content()" />

    <jsdaddy-section-wrapper
        ><jsdaddy-technologies-slider [technologies]="content()?.technologies"
    /></jsdaddy-section-wrapper>

    <jsdaddy-section-wrapper>
        <jsdaddy-case-steps [showCase]="content()" />
    </jsdaddy-section-wrapper>

    <jsdaddy-case-overview [showCase]="content()" />

    <jsdaddy-section-wrapper> <jsdaddy-development-process /> </jsdaddy-section-wrapper>

    <jsdaddy-section-wrapper>
        <jsdaddy-our-clients-say />
    </jsdaddy-section-wrapper>
}
