import { DOCUMENT, isPlatformServer, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    ViewEncapsulation,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { GridComponent } from '@shared/grid/grid.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ResizeService } from '@shared/resize/resize.service';
import { tap } from 'rxjs';
import { DevelopmentProcess } from './development-process.component.type';
import { DevelopmentProcessPipe } from './development-process.pipe';

@Component({
    selector: 'jsdaddy-development-process',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AssetPipe,
        DevelopmentProcessPipe,
        HeaderTitleComponent,
        GridComponent,
    ],
    templateUrl: './development-process.component.html',
    styleUrl: './development-process.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevelopmentProcessComponent implements AfterViewInit {
    private readonly resizeService = inject(ResizeService);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly document = inject(DOCUMENT);
    public isMobile!: boolean;

    public ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const body: HTMLBodyElement | null = this.document.querySelector('body');
        if (body) {
            this.resizeService
                .createResizeSequence(body)
                .pipe(
                    tap((width) => {
                        if (width <= 800) {
                            this.isMobile = true;
                        } else {
                            this.isMobile = false;
                        }
                        this.cdr.detectChanges();
                    })
                )
                .subscribe();
        }
    }

    public items: DevelopmentProcess[] = [
        {
            number: 1,
            title: 'Planning',
            subtitle: 'Perfect plan will kick off a project flawlessly',
        },
        {
            number: 2,
            title: 'Analysis',
            subtitle: 'Analysis is an important step to proceed further to the next point',
        },
        {
            number: 3,
            title: 'Design',
            subtitle: 'Setting a standard and sticking to it',
        },
        {
            number: 6,
            title: 'Maintenance',
            subtitle: 'Changing the existing functionality is a regular process',
        },
        {
            number: 5,
            title: 'Testing',
            subtitle: 'Checking applications for errors and bugs if there are any',
        },
        {
            number: 4,
            title: 'Implementation',
            subtitle: 'Developing software and a pilot study to check it',
        },
    ];
}
