import { Directive, HostListener, OnInit, inject, output } from '@angular/core';
import { CareerDetailsService } from './career-details.service';

@Directive({
    selector: '[jsdaddyCareerDetailsScroll]',
    standalone: true,
})
export class CareerDetailsScrollDirective implements OnInit {
    public sectionChange = output<string>();

    private readonly careerDetailsService = inject(CareerDetailsService);
    private currentSection!: string;

    public ngOnInit(): void {
        this.careerDetailsService.changeSection(this.careerDetailsService.sections[0]?.id ?? '');
    }
    @HostListener('window:scroll', ['$event'])
    public onScroll(): void {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        let closestSection: HTMLElement | null = null;
        let closestDistance = Number.POSITIVE_INFINITY;

        const children: NodeListOf<HTMLElement> = document.querySelectorAll(
            this.careerDetailsService.sections.map(({ id }) => `#${id}`).join(', ')
        );

        for (const section of Array.from(children)) {
            if (!section) {
                continue;
            }
            const distance = Math.abs(section.offsetTop - scrollTop);

            if (distance < closestDistance) {
                closestSection = section;
                closestDistance = distance;
            }
        }

        if (closestSection && closestSection.id !== this.currentSection) {
            this.currentSection = closestSection.id;
            this.sectionChange.emit(this.currentSection);
        }
    }
}
