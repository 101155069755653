import { Pipe, PipeTransform } from '@angular/core';
import { CareerPositionStyle } from './career-positions.types';

@Pipe({
    name: 'careerPositions',
    standalone: true,
})
export class CareerPositionsPipe implements PipeTransform {
    public transform(
        index: number,
        positions: CareerPositionStyle[],
        position?: CareerPositionStyle
    ): boolean {
        return index === 0 || positions[index - 1]?.position !== position?.position;
    }
}
