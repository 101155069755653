import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { ButtonPipe } from '@shared/button/button.pipe';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-border-button[title]',
    standalone: true,
    imports: [RouterLinkWithHref, ButtonPipe, AnchorLabelPipe],
    templateUrl: './border-button.component.html',
    styleUrl: './border-button.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorderButtonComponent {
    public link = input<string[] | string | undefined>();
    public title = input.required<string>();
}
