import {
    ChangeDetectionStrategy,
    Component,
    inject,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ReadyForDiscussService } from '@header/ready-for-discuss/ready-for-discuss.service';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ContactFormComponent } from '@shared/contact-form/contact-form.component';
import { HeaderPath } from '../header.path';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-ready-for-discuss',
    standalone: true,
    imports: [AssetPipe, ContactFormComponent, NgOptimizedImage],
    templateUrl: './ready-for-discuss.component.html',
    styleUrl: './ready-for-discuss.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyForDiscussComponent {
    public contactFormComponent = viewChild(ContactFormComponent);
    public readonly assetPathShared = HeaderPath.SHARED;
    public readonly assetPathContactForm = 'contact-form';

    private readyForDiscussService = inject(ReadyForDiscussService);

    public closeReadyForDiscuss(): void {
        this.contactFormComponent()?.resetForm();
        this.readyForDiscussService.showHideReadyForDiscuss(false);
    }
}
