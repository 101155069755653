@if (isFilterOpen) {
    <div class="mobile-filter column">
        <span class="h3-text full-white label">Filters:</span>
        <ng-container *ngTemplateOutlet="filter; context: mobileFilterProps" />
        <jsdaddy-button
            class="apply-filter-btn"
            (click)="toggleFilter()"
            [type]="buttonTypeYellow"
            justifyContent="space-between"
            title="Apply" />
    </div>
}
@if (!isFilterOpen) {
    <jsdaddy-header-image
        title="Case Studies"
        subtitle="our cases"
        [asset]="'header' | asset: assetPath : true" />
    <div class="open-filter column hide-tablet hide-desktop">
        <jsdaddy-button (click)="toggleFilter()" [type]="buttonTypeYellow" title="Open Filters" />

        @if (isFilterExist()) {
            <span aria-hidden="true" (click)="clearFilters()" class="text pointer">Clear All</span>
        }
    </div>
    @if (showCases()) {
        <jsdaddy-section-wrapper>
            <div class="hide-mobile main-filter">
                <ng-container *ngTemplateOutlet="filter; context: filterProps" />
            </div>
            <jsdaddy-show-cases-list
                [showCases]="showCases()"
                [title]="selectedCategoryTitle()"
                [showCaseTheme]="showCaseThemeWhite" />

            <div class="row">
                @if (hasMoreCases()) {
                    <jsdaddy-button
                        [type]="buttonTypeYellow"
                        (click)="showMore()"
                        title="Show more" />
                }
            </div>
        </jsdaddy-section-wrapper>
    }

    <jsdaddy-lets-do-work />

    <jsdaddy-section-wrapper>
        <jsdaddy-our-clients-say />
    </jsdaddy-section-wrapper>
}

<ng-template
    #filter
    let-mainCssClass="mainCssClass"
    let-textColor="textColor"
    let-chipBgColor="chipBgColor">
    <div [class]="mainCssClass" [style.--left.px]="left()">
        @for (subject of categories(); track subject.title) {
            <div
                aria-hidden="true"
                (click)="selectCategory(subject)"
                [class]="'filter-item row jc-c ai-c ' + textColor"
                [class.is-active]="subject.isActive">
                {{ subject.title }}
            </div>
        }
    </div>
    <div class="column sort-by">
        <p class="span" [class]="textColor">Sort by:</p>
        <div class="row technologies">
            @for (technology of technologiesByCategory(); track technology.title) {
                <jsdaddy-chip
                    [style.color]="technology.isActive ? 'black' : ''"
                    [chipBgColor]="chipBgColor"
                    [pointer]="true"
                    [chip]="technology.title"
                    [isActive]="technology.isActive"
                    (click)="selectTechnology(technology)"
                    [class]="['row', textColor].join(' ')" />
            }
            @if (showAllTechnologiesSignal() === false) {
                <jsdaddy-border-button
                    class="row ai-c"
                    title="Show More >"
                    (click)="showAllTechnologies()" />
            }
        </div>
    </div>
</ng-template>
