import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AfterViewInit,
    ChangeDetectorRef,
    DestroyRef,
    Directive,
    ElementRef,
    inject,
    Signal,
    signal,
} from '@angular/core';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';

@Directive()
export abstract class BaseSliderComponent implements AfterViewInit {
    public abstract slidesElement: Signal<ElementRef<HTMLElement> | undefined>;
    public abstract slide: Signal<ElementRef<HTMLElement> | undefined>;

    public activeSlideIndex = signal(0);

    protected readonly destroyRef = inject(DestroyRef);

    protected readonly service = inject(SliderEnableDisableService);
    protected readonly cdr = inject(ChangeDetectorRef);

    public ngAfterViewInit(): void {
        if (this.slidesElement) {
            this.service
                .getActiveIndex(this.slidesElement(), this.slide())
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((index) => {
                    this.activeSlideIndex.set(index);
                    this.cdr.detectChanges();
                });
        }
    }

    public goToSlide(next = true): void {
        this.checkIfScrollEnd();

        const slidesEl = this.slidesElement()?.nativeElement;
        const slideWidth = this.slide()?.nativeElement?.offsetWidth ?? 1;

        if (!slidesEl || !slideWidth) {
            return;
        }

        slidesEl.scrollTo({
            left: slidesEl.scrollLeft + (next ? 1 : -1) * slideWidth,
            behavior: 'smooth',
        });
    }

    private checkIfScrollEnd(): void {
        if (!this.service.scrollend() || !this.slide) {
            return;
        }
        this.service.scrollend.set(false);
    }
}
