import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sliderEnableDisable',
    standalone: true,
})
export class SliderEnableDisablePipe implements PipeTransform {
    public transform(
        currentIndex: number | null | undefined,
        isForward = false,
        comparingValue = 0
    ): boolean {
        if (!isForward) {
            if (!currentIndex) {
                return false;
            }
            return currentIndex !== comparingValue;
        }
        return currentIndex !== comparingValue - 1;
    }
}
