import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { ShowCasesService } from '@shared/show-cases/show-cases.service';
import {
    BehaviorSubject,
    combineLatest,
    concatAll,
    distinct,
    filter,
    from,
    map,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    toArray,
} from 'rxjs';
import { FilterItem } from './case-studies.type';

@Injectable()
export class CaseStudiesService {
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly showCasesService = inject(ShowCasesService);
    private readonly defaultFilterTitle = 'All';
    private readonly itemsPerPage = 3;

    private readonly selectedTechnologies$$ = new BehaviorSubject<FilterItem[]>([]);
    private readonly left$$ = new BehaviorSubject<number>(0);
    private page$$ = new BehaviorSubject<number>(1);
    private showAllTechnologies$$ = new BehaviorSubject<boolean>(false);
    private allCategoryFilterItem: FilterItem = { isActive: true, title: this.defaultFilterTitle };
    private readonly selectedCategory$$ = new BehaviorSubject<FilterItem>(
        this.allCategoryFilterItem
    );

    public readonly left$ = this.left$$.asObservable();
    public readonly selectedCategoryTitle$ = this.selectedCategory$$
        .asObservable()
        .pipe(map(({ title }) => title));

    public showAllTechnologies$ = this.showAllTechnologies$$.asObservable();

    public isFilterExist$ = combineLatest([
        this.selectedTechnologies$$,
        this.selectedCategory$$,
    ]).pipe(
        map(([selectedTechnologies, { title }]) => {
            return title !== this.defaultFilterTitle || selectedTechnologies?.length > 0;
        }),
        takeUntilDestroyed()
    );

    private readonly allFilteredshowCases$ = combineLatest([
        this.selectedCategory$$,
        this.selectedTechnologies$$,
    ]).pipe(
        switchMap(([{ title }, selectedTechnologies]) => {
            const titles: string[] = selectedTechnologies.map((filter: FilterItem) => filter.title);
            return this.allShowCases$.pipe(
                concatAll(),
                filter(({ category, technologies }: ShowCase) => {
                    const hasSubject: boolean =
                        category === title || title === this.allCategoryFilterItem.title;
                    const includeTechnologies =
                        !titles.length ||
                        technologies.some((tech: string) => titles.includes(tech));
                    return hasSubject && includeTechnologies;
                }),
                toArray()
            );
        }),
        takeUntilDestroyed()
    );

    public readonly showCases$ = combineLatest([this.allFilteredshowCases$, this.page$$]).pipe(
        switchMap(([allFilteredshowCases, page]) => {
            return of(allFilteredshowCases).pipe(
                concatAll(),
                take(page * this.itemsPerPage),
                toArray()
            );
        }),
        takeUntilDestroyed()
    );

    public readonly hasMoreCases$ = combineLatest([this.allFilteredshowCases$, this.page$$]).pipe(
        map(
            ([allFilteredshowCases, page]) => allFilteredshowCases.length > page * this.itemsPerPage
        ),
        takeUntilDestroyed()
    );

    public readonly categories$ = this.selectedCategory$$.pipe(
        switchMap(({ title }: FilterItem) =>
            this.filterItemsCategories$.pipe(
                map((filterItem: FilterItem) => {
                    return {
                        isActive: filterItem.title === title,
                        title: filterItem.title,
                    };
                }),
                toArray()
            )
        ),
        takeUntilDestroyed()
    );

    public readonly technologiesByCategory$ = combineLatest([
        this.selectedCategory$$,
        this.selectedTechnologies$$,
        this.showAllTechnologies$$,
    ]).pipe(
        switchMap(([{ title }, selectedTechnologies, showAllTechnologies]) => {
            return this.allShowCases$.pipe(
                concatAll(),
                filter(({ category }: ShowCase) =>
                    [category, this.allCategoryFilterItem.title].includes(title)
                ),
                switchMap(({ technologies }: ShowCase) => {
                    return from(technologies).pipe(
                        map((technology: string) => ({
                            isActive: selectedTechnologies.some(
                                ({ title }) => title === technology
                            ),
                            title: technology,
                        }))
                    );
                }),
                distinct((technology: FilterItem) => technology.title),
                take(showAllTechnologies ? Number.POSITIVE_INFINITY : 9),
                toArray()
            );
        }),
        takeUntilDestroyed()
    );

    private readonly allShowCases$ = this.showCasesService.getShowCases();

    private readonly filterItemsCategories$ = this.allShowCases$.pipe(
        concatAll(),
        distinct(({ category }: ShowCase) => category),
        map(({ category }: ShowCase) => ({ isActive: false, title: category })),
        startWith(this.allCategoryFilterItem),
        shareReplay(),
        takeUntilDestroyed()
    );

    public showAllTechnologies(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                showAllTechnologies: true,
            },
            queryParamsHandling: 'merge',
            state: { noScroll: true },
        });
    }

    public selectTechnology(technology: FilterItem): void {
        const selectedTechnologies = structuredClone(this.selectedTechnologies$$.value);
        const index = selectedTechnologies.findIndex(
            ({ title }: FilterItem) => title === technology.title
        );

        if (index > -1) {
            selectedTechnologies.splice(index, 1);
        } else {
            selectedTechnologies.push(technology);
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                technologies:
                    selectedTechnologies.map((item: FilterItem) => item.title).join(',') || null,
            },
            queryParamsHandling: 'merge',
            state: { noScroll: true },
        });
    }
    public selectCategory({ title }: FilterItem, skip = false): void {
        if (this.selectedCategory$$.value.title == title && !skip) {
            return;
        }
        const queryParams = { category: title };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            state: { noScroll: true },
        });
    }

    public showMore(): void {
        const page = this.page$$.value;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                page: Number(page) + 1,
            },
            queryParamsHandling: 'merge',
            state: { noScroll: true },
        });
    }
    public clearFilters(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                category: this.defaultFilterTitle,
                technologies: null,
                page: 1,
            },
            state: { noScroll: true },
        });
    }

    public handleParams(params: Params, categories: FilterItem[] | undefined): void {
        const {
            category,
            technologies,
            page,
            showAllTechnologies,
            //type-coverage:ignore-next-line
        } = params as {
            showAllTechnologies: string;
            category: string;
            technologies: string;
            page: number;
        };
        let newCategory = category;
        const technologiesParams: string[] = technologies?.split(',') ?? [];
        if (technologiesParams.length) {
            this.showAllTechnologies$$.next(true);
        } else {
            this.showAllTechnologies$$.next(Boolean(showAllTechnologies));
        }
        this.page$$.next(page ?? 1);
        if (newCategory) {
            const currentIndex = categories?.findIndex(({ title }) => title === newCategory);
            if ((currentIndex ?? 0) < 0) {
                newCategory = this.defaultFilterTitle;
            }
            this.selectedCategory$$.next({
                title: newCategory,
                isActive: true,
            });
            this.left$$.next(180 * (currentIndex ?? 0));

            this.selectedTechnologies$$.next(
                technologiesParams.map((title: string) => ({
                    title,
                    isActive: true,
                }))
            );
        } else {
            this.selectedTechnologies$$.next([]);
            this.selectCategory(this.allCategoryFilterItem, true);
        }
    }
}
