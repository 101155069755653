import { inject, Injectable } from '@angular/core';
import { BodyStylesService } from '@shared/body-styles/body-styles.service';
import { BehaviorSubject, tap } from 'rxjs';
import { FormSendingDialogType } from './form-sending-dialog.component';

@Injectable()
export class FormSendingDialogService {
    private readonly bodyStylesService = inject(BodyStylesService);
    private readonly type$$ = new BehaviorSubject<FormSendingDialogType>(
        FormSendingDialogType.LOADING
    );
    private readonly isOpened$$ = new BehaviorSubject<boolean>(false);

    public readonly type$ = this.type$$.asObservable();
    public readonly isOpened$ = this.isOpened$$.asObservable().pipe(
        tap((isOpened) => {
            this.bodyStylesService.setOverflowBody(isOpened);
        })
    );

    public changeToLoading(): void {
        this.changeType(FormSendingDialogType.LOADING);
    }

    public changeToSuccess(): void {
        this.changeType(FormSendingDialogType.SUCCESS);
    }

    public changeToError(): void {
        this.changeType(FormSendingDialogType.ERROR);
    }

    public open(): void {
        this.isOpened$$.next(true);
        this.changeToLoading();
    }

    public close(): void {
        this.changeToLoading();
        this.isOpened$$.next(false);
    }

    private changeType(type: FormSendingDialogType): void {
        this.type$$.next(type);
    }
}
