<div class="img-header" [style.background-image]="asset() | imageUrl">
    <jsdaddy-section-wrapper>
        <div class="column jc-sb">
            <jsdaddy-breadcrumbs />
            <div class="row">
                <jsdaddy-header-title
                    [isSubtitleMoreThanText]="isSubtitleMoreThanText()"
                    [title]="title()"
                    [subtitle]="subtitle()"
                    [needStar]="true" />
            </div>
        </div>
    </jsdaddy-section-wrapper>
</div>
