import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'indent',
    standalone: true,
})
export class IndentPipe implements PipeTransform {
    public transform(text: string | undefined): string | undefined {
        return text?.replaceAll('\n', '<br/>');
    }
}
