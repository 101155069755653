@if (black() ? 'black' : 'white'; as textColorClass) {
    <div class="column">
        @if (subtitle()) {
            <span
                [style.text-align]="isSubtitleMoreThanText() ? 'left' : 'right'"
                [style.max-width]="subtitleWidth ? subtitleWidth + 'px' : 'auto'"
                class="text ls-25 uppercase subtitle"
                [class]="textColorClass">
                <span #subtitleElement>
                    {{ subtitle() }}
                </span>
            </span>
        }
        <span
            [style.margin-left]="isTitleRightSide() ? 'auto' : 'inherit'"
            [style.text-align]="!isSubtitleMoreThanText() ? 'left' : 'right'"
            [style.max-width]="textWidth ? textWidth + 'px' : 'auto'"
            [class]="[textColorClass, 'h1-text', 'pos-rel'].join(' ')">
            <span #textElement>{{ title() }}</span>
            @if (needStar()) {
                <img
                    [style.left.px]="left"
                    [ngSrc]="'yellow-plus' | asset: assetPath"
                    width="9"
                    height="9"
                    class="yellow-plus"
                    alt="Yellow plus" />
            }
        </span>
    </div>
}
