import { DestroyRef, ElementRef, inject, Injectable, signal } from '@angular/core';
import { EMPTY, fromEvent, map, Observable, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class SliderEnableDisableService {
    private readonly destroyRef = inject(DestroyRef);
    public scrollend = signal(true);
    public getActiveIndex(
        slidesElement: ElementRef<HTMLElement> | undefined,
        slide: ElementRef<HTMLElement> | undefined
    ): Observable<number> {
        if (!slidesElement || !slide) {
            return EMPTY;
        }

        return fromEvent(slidesElement.nativeElement, 'scroll').pipe(
            tap(() => {
                this.scrollend.set(true);
            }),
            map(() => {
                const slideWidth = slide.nativeElement.offsetWidth;
                return Math.round(slidesElement.nativeElement.scrollLeft / slideWidth);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }
}
