<section jsdaddyCareerDetailsScroll (sectionChange)="onSectionChange($event)">
    <span class="text">{{ career()?.description }}</span>
    @for (tile of tiles(); track tile.title) {
        <section [id]="tile.id">
            <div class="column item">
                <span class="h3-text item-title">{{ tile.title }}</span>
                @for (item of tile.texts; track item) {
                    <div class="row ai-c">
                        <span class="dot bg-yellow ais-start"></span>
                        <span class="item-text text">{{ item }}</span>
                    </div>
                }
            </div>
        </section>
    }
</section>
