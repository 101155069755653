@if (showCase() && showCase()?.images?.[1]) {
    <div
        class="img-overview"
        [style.background-image]="
            showCase()?.images?.[1]?.toString()
                | asset: assetPathSharedShowCases + '/' + showCase()?.assetsFolder : true
                | imageUrl
        ">
        <jsdaddy-section-wrapper>
            <div class="overview pos-rel column">
                <div
                    class="overview-plus hide-tablet hide-mobile"
                    [style.background-image]="'obj-plus' | asset: assetPath | imageUrl"></div>
                <jsdaddy-header-title
                    class="row"
                    [subtitle]="showCase()?.title"
                    [needStar]="true"
                    title="Project Overview" />
                <jsdaddy-row-black-white-wrapper title="details">
                    <div left class="column details-texts">
                        <span class="h3-text full white">Duration</span>
                        <span class="details-texts-subtitle span full-white"
                            >{{ showCase()?.startDate }} - {{ showCase()?.endDate }}</span
                        >
                        <span class="h3-text full white">Industry</span>
                        <span class="span details-texts-subtitle full-white">{{
                            showCase()?.industry
                        }}</span>
                        <span class="h3-text full white">Country</span>
                        <span class="details-texts-subtitle span full-white">{{
                            showCase()?.country
                        }}</span>
                        <span class="h3-text full white">Solutions</span>
                        <div class="chips full-white row details-texts-subtitle">
                            @for (solution of showCase()?.solutions || []; track solution) {
                                <jsdaddy-chip
                                    class="chip"
                                    chipBgColor="rgba(255, 255, 255, 0.25)"
                                    [chip]="solution"></jsdaddy-chip>
                            }
                        </div>
                        <span class="h3-text full white">Technologies</span>
                        <div class="chips full-white row details-texts-subtitle">
                            @for (technology of showCase()?.technologies || []; track technology) {
                                <jsdaddy-chip
                                    class="chip"
                                    chipBgColor="rgba(255, 255, 255, 0.25)"
                                    [chip]="technology"></jsdaddy-chip>
                            }
                        </div>
                    </div>
                    <div class="column" right>
                        <span class="h3-text">{{ showCase()?.title }}</span>
                        <span
                            class="span first-desc"
                            [innerHTML]="showCase()?.description | indent">
                        </span>
                        <span class="h3-text gallery-title">Project Gallery</span>
                        <div class="slider pos-rel row jc-c">
                            <jsdaddy-arrow-button
                                [enabled]="activeSlideIndex() | sliderEnableDisable"
                                class="back"
                                (click)="goToSlide(false)"
                                [direction]="backDirection" />
                            <jsdaddy-arrow-button
                                class="forward"
                                (click)="goToSlide()"
                                [enabled]="
                                    activeSlideIndex()!
                                        | sliderEnableDisable: true : showCase()?.images?.length
                                " />
                            <div #slides class="slides row">
                                @for (image of showCase()?.images || []; track image) {
                                    <div #slide class="slide ai-c row jc-c">
                                        <jsdaddy-image-with-frame
                                            [image]="image"
                                            [showCase]="showCase()" />
                                    </div>
                                }
                            </div>
                        </div>
                        <jsdaddy-button
                            class="ais-c"
                            [link]="contactUsPath"
                            [type]="primaryYellowButtonType"
                            title="Want to Discuss!" />
                    </div>
                </jsdaddy-row-black-white-wrapper>
            </div>
        </jsdaddy-section-wrapper>
    </div>
}
