<a
    [href]="linkedin()"
    target="_blank"
    [style.background-image]="image() | imageUrl"
    class="image pos-rel"
    aria-label="Read more about JSDaddy client">
    <img
        alt="Linkedin icon"
        width="24"
        height="24"
        [ngSrc]="'shared/our-clients-say/linkedin-circle' | asset" />
</a>
