import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'showCasesListOthers',
    standalone: true,
})
export class ShowCasesListOthersPipe implements PipeTransform {
    private readonly and = 'AND';
    private readonly itemsPerPage = 3;

    public transform(showCasesLength: number): string {
        if (showCasesLength - this.itemsPerPage < 1) {
            return this.and;
        }
        return `${this.and} ${showCasesLength - this.itemsPerPage}`;
    }
}
