import { Directive, ElementRef, HostListener, inject, Renderer2 } from '@angular/core';

@Directive({
    selector: '[jsdaddyLetsDoWork]',
    standalone: true,
})
export class LetsDoDirective {
    private htmlElement!: HTMLElement | null;

    private readonly el: ElementRef<HTMLElement> = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    @HostListener('mousemove', ['$event'])
    public onMouseMove(event: MouseEvent): void {
        if (!this.htmlElement) {
            this.createElement();
        }
        this.moveElement(event);
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        if (this.htmlElement) {
            this.renderer.removeChild(this.el.nativeElement, this.htmlElement);
            this.htmlElement = null;
        }
    }

    private createElement(): void {
        this.htmlElement = this.renderer.createElement('div');
        this.renderer.addClass(this.htmlElement, 'block');
        this.renderer.setStyle(this.htmlElement, 'position', 'absolute');
        this.renderer.appendChild(this.el.nativeElement, this.htmlElement);
    }

    private moveElement(event: MouseEvent): void {
        const imageRect = this.el.nativeElement.getBoundingClientRect();
        const x = event.clientX - imageRect.left;
        const y = event.clientY - imageRect.top;

        this.renderer.setStyle(this.htmlElement, 'left', `${x - 83}px`);
        this.renderer.setStyle(this.htmlElement, 'top', `${y - 83}px`);
    }
}
