import { inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

export function metaTagsResolver(route: ActivatedRouteSnapshot): Observable<boolean> {
    const titleService = inject(Title);
    const metaTagService = inject(Meta);

    const title: string | undefined = route.data['title'];
    const metaTags: Record<string, string> = {
        'og:type': 'website',
        ...route.data['metaTags'],
    };

    if (title) {
        titleService.setTitle(title);
    }

    Object.entries(metaTags).forEach(([key, content]) => {
        if (content) {
            metaTagService.updateTag({ name: key, content });
        }
    });

    return of(true);
}
