export const enum ShowCaseTheme {
    BLACK = 'black',
    WHITE = 'white',
}

export enum ShowCaseType {
    MOBILE = 'mobile',
    LANDSCAPE_TABLET = 'landscape-tablet',
    PORTRAIT_TABLET = 'portrait-tablet',
    WEB = 'web',
}
