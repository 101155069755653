<jsdaddy-grid class="grid" [hideTablet]="true" />
<div class="row header">
    <div class="row">
        <jsdaddy-header-title
            [needStar]="true"
            [black]="true"
            subtitle="Workflow"
            title="Development Process" />
    </div>
    <span class="span"
        >Our development services company has a proven six-step path ensuring you get the maximum
        profit for your investment:</span
    >
</div>

<div class="row numbers-container">
    <ng-container *ngTemplateOutlet="allItems; context: { $implicit: items }" />
</div>

<ng-template #allItems let-items>
    @for (
        item of items | developmentProcess: isMobile;
        track item.title;
        let i = $index;
        let last = $last
    ) {
        <div class="row full-element">
            @if (i !== 2 && !isMobile) {
                <span class="number yellow">{{ item.number }}</span>
                <div class="column content">
                    <div>
                        @if (!last) {
                            <div class="container">
                                <div class="dotted-line"></div>
                            </div>
                        }
                    </div>
                    <span class="h3-text">{{ item.title }}</span>
                    <span class="span subtitle-text">{{ item.subtitle }}</span>
                </div>
            } @else {
                <div class="column ai-c">
                    <span class="number yellow">{{ item.number }}</span>
                    <div class="container vertical">
                        <div class="dotted-line vertical"></div>
                    </div>
                </div>
                <div class="column content jc-c">
                    <span class="h3-text">{{ item.title }}</span>
                    <span class="span subtitle-text">{{ item.subtitle }}</span>
                </div>
            }
        </div>
    }
</ng-template>
