import { isPlatformServer } from '@angular/common';
// type-coverage:ignore-next-line
import { ApplicationRef, DestroyRef, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, map, Observable, startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ResizeService {
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly cdr = inject(ApplicationRef);
    private readonly destroyRef = inject(DestroyRef);

    public createResizeSequence(element: HTMLElement | undefined): Observable<number> {
        if (isPlatformServer(this.platformId) || !element) {
            return EMPTY;
        }
        return new Observable<DOMRectReadOnly>((subscriber) => {
            const resizeObserver = new ResizeObserver((entries) => {
                for (const { contentRect } of entries) {
                    subscriber.next(contentRect);
                    this.cdr.tick();
                }
            });
            resizeObserver.observe(element);
            return () => {
                resizeObserver.disconnect();
            };
        })
            .pipe(
                map(({ width }: DOMRectReadOnly) => width),
                startWith(element.offsetWidth)
            )
            .pipe(takeUntilDestroyed(this.destroyRef));
    }
}
