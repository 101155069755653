<jsdaddy-grid class="grid-bottom" [hideMobile]="true" />
<jsdaddy-grid class="grid-top" />

<img
    alt="Yellow plus"
    [ngSrc]="'yellow-plus' | asset: assetPathShared"
    class="yellow-plus bottom"
    width="9"
    height="9" />
<div class="row jc-sb ai-c header">
    <jsdaddy-header-title [black]="true" title="OpenSource" subtitle="library" [needStar]="true" />
    <div class="row ai-c all-library">
        <jsdaddy-button
            [link]="jsdaddyGithub"
            class="all-library-btn"
            [type]="buttonTypePrimray"
            title="All Library" />
        <img
            alt="Github"
            class="github-header"
            width="49"
            height="49"
            [ngSrc]="'github' | asset: assetPathShared" />
    </div>
</div>
<div class="column">
    <div class="row projects remove-mobile-wrapper-padding">
        <div class="ngx-mask row">
            <div class="column content jc-sb">
                <div class="column">
                    <h2 class="white">Ngx Mask</h2>
                    <p class="span white ngx-mask-desc">
                        We know that thousands people use Angular for building awesome solutions for
                        businesses...
                    </p>
                </div>
                <div class="row ngx-mask-stats">
                    @for (maskProp of maskProps(); track maskProp.text) {
                        <div class="column ngx-mask-tile">
                            <span class="yellow h4-text">{{ maskProp.value | openSource }}</span>
                            <div class="column mask-prop ai-c">
                                <img
                                    [alt]="maskProp.text"
                                    class="ngx-mask-img"
                                    [ngSrc]="maskProp.icon | asset"
                                    width="26"
                                    height="26" />
                                <p class="title white ls-25 uppercase">
                                    {{ maskProp.text }}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <jsdaddy-button
                    [link]="jsdaddyGithub + 'ngx-mask'"
                    class="btn"
                    [type]="buttonTypeGhostWhite"
                    title="Discover More!" />
            </div>
        </div>
        <div class="row ngx-other-row">
            <div class="column ngx-other jc-sb">
                @for (project of projects(); track project.title) {
                    <a
                        class="item column jc-sb"
                        [href]="jsdaddyGithub + project.url"
                        target="_blank">
                        <img
                            [alt]="project.title"
                            width="200"
                            height="185"
                            [ngSrc]="project.img | asset: assetPath"
                            class="image" />
                        <div class="column item-header">
                            <h3 [attr.aria-label]="project.title | anchorLabel">
                                {{ project.title }}
                            </h3>
                            <p class="span">{{ project.text }}</p>
                        </div>
                        <div class="row jc-sb">
                            <div class="row">
                                <div class="row github ai-c github-wrap">
                                    <img
                                        alt="Github"
                                        [ngSrc]="'github' | asset: assetPathShared"
                                        height="16"
                                        width="16" />
                                    <img
                                        alt="Stars"
                                        height="11"
                                        width="71"
                                        class="stars-img"
                                        [ngSrc]="'stars' | asset: assetPath" />
                                </div>
                                <div class="row ai-c counter white github-wrap span">
                                    {{ project.rate || 0 }}
                                </div>
                            </div>

                            <img
                                alt="Github"
                                class="arrow-top-right"
                                width="24"
                                height="24"
                                [ngSrc]="'arrow-top-right-black' | asset: assetPathShared" />
                        </div>
                    </a>
                }
            </div>
            <ng-container
                *ngTemplateOutlet="
                    weHave;
                    context: { $implicit: 'hide-desktop hide-mobile' }
                "></ng-container>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="weHave; context: { $implicit: 'hide-tablet' }"></ng-container>
</div>

<ng-template #weHave let-class>
    <p [class]="'text we-have' + ' ' + class">
        We have created OpenSource projects and have experience in effective solving the problems of
        our clients
    </p>
</ng-template>
