@if (feedbacks) {
    <div class="jc-sb row our-clients-say">
        <jsdaddy-header-title
            [black]="true"
            class="ais-start header-title"
            [needStar]="true"
            title=" Our Clients Say"
            subtitle="JS DADDY" />
        @if (feedbacks.length > 1) {
            <div class="row buttons">
                <jsdaddy-arrow-button
                    [enabled]="activeSlideIndex() | sliderEnableDisable"
                    (click)="goToSlide(false)"
                    [direction]="backDirection" />
                <jsdaddy-arrow-button
                    (click)="goToSlide()"
                    [enabled]="activeSlideIndex() | sliderEnableDisable: true : feedbacks.length" />
            </div>
        }
    </div>

    <div class="slider">
        <div #slides class="slides row">
            @for (feedback of feedbacks; track feedback.text) {
                <div #slide class="slide ai-c row">
                    <div class="row jc-sb persons">
                        <jsdaddy-photo-card
                            [image]="feedback.image | asset: additionalPath : true"
                            [linkedin]="feedback.linkedin"
                            class="image" />
                        <div class="column info jc-sb hide-tablet hide-desktop">
                            <h2>{{ feedback.name }}</h2>
                            <h3>{{ feedback.position }}</h3>
                        </div>
                    </div>

                    <div class="text column">
                        <div class="main">
                            <div class="row">
                                <h1 class="brackets">"</h1>
                                <p class="span">
                                    {{ feedback.text }}
                                </p>
                            </div>
                            <p class="sub-text span">{{ feedback.subText }}</p>
                        </div>

                        <div class="row jc-sb">
                            <div class="column personal-info jc-sb hide-mobile">
                                <h2>{{ feedback.name }}</h2>
                                <h3>{{ feedback.position }}</h3>
                            </div>
                            <p class="next span hide-mobile">NEXT</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="row slider button-container">
        <jsdaddy-button
            [link]="contactUsPath"
            title="Become a Client!"
            class="button"
            [type]="buttonTypePrimaryYellow" />
    </div>
    <jsdaddy-grid class="plus-grid" [hideMobile]="true" [hideTablet]="true" />
    <img
        alt="Head with glasses"
        width="100"
        height="100"
        [ngSrc]="'head' | asset: assetPathShared"
        class="head" />
}
