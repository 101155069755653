<jsdaddy-section-wrapper>
    <jsdaddy-banner />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper overflow="auto" [black]="true" [noize]="true">
    <jsdaddy-services />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper>
    <jsdaddy-company-id />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper>
    <jsdaddy-open-source />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper>
    <jsdaddy-technologies />
</jsdaddy-section-wrapper>

<jsdaddy-lets-do-work />

<jsdaddy-section-wrapper>
    <jsdaddy-choose-us />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper [black]="true" [noize]="true">
    <jsdaddy-show-cases />
</jsdaddy-section-wrapper>

<jsdaddy-section-wrapper>
    <jsdaddy-our-clients-say />
</jsdaddy-section-wrapper>
