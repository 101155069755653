@if ((technologies()?.length || 0) > 5) {
    <button
        (click)="goToSlide(false)"
        aria-label="Back slide"
        class="pointer"
        [class.disabled]="!(activeSlideIndex() | sliderEnableDisable)">
        <img
            width="16"
            height="16"
            class="back"
            [ngSrc]="'arrow-back-black' | asset: assetPathArrowButton"
            alt="Arrow black back" />
    </button>
}
<div #slides class="slides row">
    @for (image of technologies(); track image) {
        <div #slide class="slide row" [style.width.%]="slideWidth()">
            <img
                [alt]="image"
                width="100"
                height="100"
                [ngSrc]="image | technologiesSlider | lowercase | asset: assetPathTechnologies" />
        </div>
    }
</div>
@if ((technologies()?.length || 0) > 5) {
    <button
        (click)="goToSlide()"
        aria-label="Forward slide"
        class="pointer"
        [class.disabled]="!(activeSlideIndex() | sliderEnableDisable: true : slideInBlockLength())">
        <img
            aria-hidden="true"
            (click)="goToSlide()"
            width="16"
            height="16"
            class="forward"
            [ngSrc]="'arrow-forward-black' | asset: assetPathArrowButton"
            alt="Arrow forward black" />
    </button>
}
