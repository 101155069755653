import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { AssetPipe } from '@libraries/asset/asset.pipe';

@Component({
    selector: 'jsdaddy-grid',
    standalone: true,
    imports: [AssetPipe, NgOptimizedImage],
    templateUrl: './grid.component.html',
    styleUrl: './grid.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent {
    public hideMobile = input(false);
    public hideTablet = input(false);
    public hideDesktop = input(false);

    public readonly assetPath = SharedAssetPath.GRID;
}
