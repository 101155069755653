import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-upwork-svg',
    standalone: true,
    templateUrl: './upwork-svg.component.html',
    styleUrl: './upwork-svg.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AnchorLabelPipe],
})
export class UpworkSvgComponent {
    public isHeader = input(true);
    public smallSize = input(false);
}
