import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LetsDoWorkComponent } from '@shared/lets-do-work/lets-do-work.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { BannerComponent } from '../../content/home/banner/banner.component';
import { OurClientsSayComponent } from '../../shared/our-clients-say/our-clients-say.component';
import { ChooseUsComponent } from './choose-us/choose-us.component';
import { OpenSourceComponent } from './open-source/open-source.component';
import { ServicesComponent } from './services/services.component';
import { ShowCasesComponent } from './show-cases/show-cases.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import { CompanyIdComponent } from '@content/home/company-id/company-id.component';

@Component({
    selector: 'jsdaddy-home',
    standalone: true,
    imports: [
        ServicesComponent,
        ChooseUsComponent,
        SectionWrapperComponent,
        OpenSourceComponent,
        ShowCasesComponent,
        TechnologiesComponent,
        OurClientsSayComponent,
        BannerComponent,
        LetsDoWorkComponent,
        CompanyIdComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {}
