import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
// type-coverage:ignore-next-line
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable()
export class HtmlPageTitleStrategy extends TitleStrategy {
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly document = inject(DOCUMENT);
    public override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState) ?? '';
        if (!title || isPlatformServer(this.platformId)) {
            return;
        }
        this.document.title = title;
    }
}
