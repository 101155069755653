import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'button',
    standalone: true,
})
export class ButtonPipe implements PipeTransform {
    public transform(link: string | string[]): boolean {
        if (Array.isArray(link)) {
            return link.includes('http');
        }
        return link.startsWith('http');
    }
}
