<div class="column black-white-first-column">
    <div class="column black-white-details">
        <span class="black-white-details-title row uppercase">{{ title() }}</span>
        <div class="column black-white-details-texts">
            <ng-content select="[left]" />
        </div>
    </div>
</div>
<div class="column black-white-definition bg-full-white pos-rel">
    <ng-content select="[right]" />
    @if (needBottomImg()) {
        <div class="img"></div>
    }
</div>
