<div class="content column jc-sb">
    <button (click)="close($event)" class="close-btn" aria-label="Close menu">
        <img
            width="25"
            height="25"
            [ngSrc]="'close-btn-black' | asset: assetPath"
            alt="Close button" />
    </button>
    <div class="back-btn ai-c row">
        <button (click)="closeSelfEmit($event)" aria-label="Close Me">
            <img
                width="24"
                height="24"
                [ngSrc]="'arrow-back' | asset: assetPath"
                alt="Back button" />
        </button>
    </div>
    <div class="list text">
        <ul>
            @for (service of servicesJson; track service.title) {
                <li class="pos-rel">
                    <a
                        [attr.aria-label]="service.title | anchorLabel"
                        class="span"
                        [routerLink]="[services, service.slug]"
                        (click)="close($event)"
                        >{{ service.title }}</a
                    >
                </li>
            }
        </ul>
    </div>
    <div class="discuss">
        <div class="question ls-25 uppercase">have questions?</div>
        <div class="discuss-your-project">Let's<br />Discuss Your<br />Project!</div>
        <jsdaddy-border-button
            [link]="contactUs"
            title="Want to Discuss!"
            (click)="close($event)" />
    </div>
</div>
