import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import showCasesJson from 'src/assets/json/show-cases.json';
import { ShowCase } from './show-cases.type';

@Injectable()
export class ShowCasesService {
    public getShowCases(): Observable<ShowCase[]> {
        return of(
            //type-coverage:ignore-next-line
            (showCasesJson as unknown as ShowCase[]).map(
                (showCase): ShowCase => ({
                    ...showCase,
                    type: showCase.type,
                })
            ) as ShowCase[]
        );
    }
}
