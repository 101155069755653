import { Pipe, PipeTransform } from '@angular/core';
import { ShowCaseType } from '../show-cases-list/show-cases-list.enum';

@Pipe({
    name: 'showCaseIsMobile',
    standalone: true,
})
export class ShowCaseIsMobilePipe implements PipeTransform {
    public transform(showCaseType: ShowCaseType | undefined): boolean {
        if (!showCaseType) {
            return false;
        }
        return [ShowCaseType.MOBILE, ShowCaseType.PORTRAIT_TABLET].includes(showCaseType);
    }
}
