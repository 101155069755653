import { Pipe, PipeTransform } from '@angular/core';
import { ScreenSize } from '@shared/screen-size/screen-size.enum';

@Pipe({
    name: 'rightContent',
    standalone: true,
})
export class RightContentPipe implements PipeTransform {
    public transform(isShow: boolean, innerWidth: number): string {
        if (isShow) {
            return 'hide';
        }
        if (innerWidth > ScreenSize.DESKTOP) {
            return 'desktop';
        }
        return innerWidth <= ScreenSize.MOBILE ? 'mobile' : 'tablet';
    }
}
