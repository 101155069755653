<a
    href="https://www.upwork.com/o/companies/~0111c96f85d0f73255/"
    target="_blank"
    [attr.aria-label]="'Upwork' | anchorLabel"
    [class.small-size]="smallSize()">
    <div class="row">
        <svg
            class="first"
            width="68"
            [attr.fill]="isHeader() ? '#d1d1d1' : '#fff'"
            height="64"
            viewBox="0 0 68 64"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M55.2874 21.3493C55.2874 26.9345 53.1427 32.0188 49.632 35.8235H18.2442C14.7334 32.0188 12.5887 26.9345 12.5887 21.3493C12.5887 9.55843 22.1472 0 33.9381 0C45.729 0 55.2874 9.55843 55.2874 21.3493Z" />
            <path
                d="M42.2022 13.7967C38.9254 13.8126 36.4336 15.9877 35.5014 19.4535C33.9371 17.0376 32.7165 14.2743 32.0163 11.941L28.5669 11.9569L28.6097 21.1304C28.6135 22.0026 28.272 22.8406 27.6602 23.4602C27.0485 24.0799 26.2165 24.4304 25.3472 24.4348C24.478 24.4386 23.6428 24.096 23.0252 23.4821C22.4077 22.8683 22.0583 22.0336 22.0539 21.1614L22.0103 11.9879L18.5593 12.0038L18.6021 21.1773C18.6203 24.984 21.6515 28.0007 25.3615 27.9824C29.0715 27.9642 32.0765 24.9212 32.0591 21.1137L32.0512 19.556C32.7466 20.9365 33.6163 22.4035 34.5723 23.6981L32.4631 33.8332L35.9988 33.8173L37.5196 26.4534C38.9033 27.3133 40.4502 27.8243 42.2687 27.8156C46.0642 27.7973 49.1556 24.6685 49.1358 20.6869C49.1214 18.8525 48.3843 17.0983 47.0854 15.8072C45.7865 14.5162 44.0313 13.7931 42.203 13.7959L42.2022 13.7967ZM42.2513 24.2687C40.8715 24.2751 39.4894 23.6759 38.3639 22.7286L38.7021 21.3434V21.2639C38.9555 19.7905 39.7175 17.2776 42.3059 17.2665C43.2186 17.2649 44.0949 17.6252 44.7442 18.2687C45.3935 18.9123 45.7632 19.7871 45.7728 20.7028C45.6951 22.6936 44.0643 24.2592 42.2521 24.2679L42.2513 24.2687Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <rect x="7.34167" y="36.9089" width="53.0115" height="10.3128" />
            <path
                d="M38.9856 51.0755C37.4943 51.0828 36.2796 52.3223 36.2864 53.8656C36.2933 55.409 37.5202 56.6369 39.0114 56.6299C40.5026 56.623 41.7177 55.3832 41.7106 53.8404C41.7034 52.2977 40.4768 51.0689 38.9849 51.0761L38.9856 51.0755ZM39.0061 55.4981C38.5833 55.5006 38.1771 55.3328 37.8766 55.0321C37.5762 54.7314 37.4097 54.3218 37.405 53.8943C37.4003 53.4667 37.5684 53.0559 37.8657 52.752C38.1631 52.4482 38.5681 52.2798 38.9909 52.2751C39.4136 52.2703 39.8199 52.4404 40.1203 52.741C40.4208 53.0417 40.5873 53.4513 40.592 53.8789C40.5963 54.7368 39.8542 55.4937 39.0061 55.4981ZM45.0271 52.3837C44.2472 52.3875 43.6398 53.0417 43.6436 53.8304L43.656 56.4703L42.4693 56.476L42.4453 51.2298L43.632 51.2241L43.6361 52.0468C43.6361 52.0468 44.1404 51.2219 45.1578 51.2172L45.4972 51.2153L45.5028 52.3808L45.0271 52.3837ZM33.1888 51.2745L34.0872 54.8706L35.02 51.2657L36.2067 51.2603L34.7385 56.5166L33.586 56.5219L32.6539 52.8578L31.7554 56.5304L30.6026 56.5358L29.0529 51.2968L30.2054 51.2915L31.239 54.887L32.0702 51.2827L33.1888 51.2745ZM48.965 53.5999C49.776 53.1163 50.3488 52.2219 50.3441 51.1933L49.1574 51.1989C49.1617 52.0906 48.4525 52.8141 47.6051 52.8172L47.4355 52.8178L47.4168 48.6692L46.23 48.6749L46.2664 56.4577L47.4526 56.4524L47.4414 53.984L47.5768 53.9834C47.7125 53.9828 47.8486 54.0508 47.8831 54.1534L49.5549 56.4423L50.945 56.436L48.965 53.5999Z"
                [attr.fill]="isHeader() ? '#4B4B4B' : '#fff'" />
            <path
                d="M26.3041 51.1992C25.0051 51.2054 24.0172 52.0503 23.6476 53.3965C23.0274 52.458 22.5435 51.3847 22.2659 50.4784L20.8984 50.4846L20.9154 54.0478C20.9169 54.3866 20.7815 54.7121 20.539 54.9528C20.2964 55.1935 19.9666 55.3296 19.622 55.3313C19.2774 55.3328 18.9462 55.1997 18.7014 54.9613C18.4566 54.7229 18.3181 54.3986 18.3163 54.0598L18.2991 50.4966L16.9309 50.5028L16.9479 54.066C16.9551 55.5447 18.1568 56.7165 19.6276 56.7094C21.0985 56.7023 22.2898 55.5203 22.2829 54.0413L22.2798 53.4363C22.5555 53.9725 22.9002 54.5423 23.2793 55.0452L22.443 58.9819L23.8448 58.9758L24.4477 56.1154C24.9963 56.4494 25.6095 56.6479 26.3305 56.6445C27.8352 56.6374 29.0608 55.4221 29.053 53.8756C29.0472 53.163 28.755 52.4816 28.2401 51.9801C27.7251 51.4787 27.0293 51.1978 26.3044 51.1989L26.3041 51.1992ZM26.3236 55.2668C25.7766 55.2693 25.2286 55.0365 24.7824 54.6686L24.9165 54.1305V54.0997C25.017 53.5273 25.3191 52.5513 26.3452 52.5469C26.7071 52.5464 27.0545 52.6863 27.3119 52.9363C27.5693 53.1862 27.7159 53.526 27.7197 53.8817C27.6889 54.655 27.0424 55.2631 26.3239 55.2665L26.3236 55.2668Z"
                fill="#6FDA44" />
            <path
                d="M51.8497 51.3196V51.2021H52.6357V51.3196H52.3189V52.1068H52.1661V51.3196H51.8497Z"
                fill="#4B4B4B" />
            <path
                d="M52.8094 51.2021H52.9954L53.3187 51.9054H53.3306L53.654 51.2021H53.8399V52.1068H53.6941V51.4522H53.6847L53.3852 52.1055H53.2642L52.9647 51.4517H52.9552V52.1068H52.8094V51.2021Z"
                fill="#4B4B4B" />
            <path d="M61.6196 37.9946H67.4997L64.8763 42.0655L67.4997 46.1363H61.6196V37.9946Z" />
            <path
                d="M5.98486 46.1364L0.104746 46.1364L2.72818 42.0655L0.104747 37.9947L5.98486 37.9947L5.98486 46.1364Z" />
            <path
                d="M21.0018 58.9822L21.6809 60.49L23.3248 60.6699L22.1006 61.7817L22.4375 63.4007L21.0018 62.58L19.5662 63.4007L19.9031 61.7817L18.6789 60.6699L20.3228 60.49L21.0018 58.9822Z" />
            <path
                d="M27.3342 58.9822L28.0133 60.49L29.6572 60.6699L28.433 61.7817L28.7699 63.4007L27.3342 62.58L25.8986 63.4007L26.2355 61.7817L25.0113 60.6699L26.6552 60.49L27.3342 58.9822Z" />
            <path
                d="M33.6668 58.9822L34.3458 60.49L35.9897 60.6699L34.7655 61.7817L35.1024 63.4007L33.6668 62.58L32.2311 63.4007L32.568 61.7817L31.3438 60.6699L32.9877 60.49L33.6668 58.9822Z" />
            <path
                d="M39.9992 58.9822L40.6782 60.49L42.3221 60.6699L41.0979 61.7817L41.4348 63.4007L39.9992 62.58L38.5635 63.4007L38.9004 61.7817L37.6762 60.6699L39.3201 60.49L39.9992 58.9822Z" />
            <path
                d="M46.3315 58.9822L47.0106 60.49L48.6545 60.6699L47.4303 61.7817L47.7672 63.4007L46.3315 62.58L44.8959 63.4007L45.2328 61.7817L44.0086 60.6699L45.6525 60.49L46.3315 58.9822Z" />
            <path
                d="M12.7697 40.4682V39.5165H17.2229V40.4682H15.5629V44.976H14.4297V40.4682H12.7697Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M22.6915 42.2463C22.6915 42.8416 22.5794 43.3481 22.3552 43.7657C22.1328 44.1834 21.8292 44.5024 21.4444 44.7227C21.0614 44.9413 20.6308 45.0506 20.1524 45.0506C19.6706 45.0506 19.2381 44.9404 18.8551 44.7201C18.4721 44.4997 18.1694 44.1807 17.947 43.7631C17.7246 43.3454 17.6134 42.8398 17.6134 42.2463C17.6134 41.6509 17.7246 41.1444 17.947 40.7268C18.1694 40.3092 18.4721 39.991 18.8551 39.7724C19.2381 39.5521 19.6706 39.4419 20.1524 39.4419C20.6308 39.4419 21.0614 39.5521 21.4444 39.7724C21.8292 39.991 22.1328 40.3092 22.3552 40.7268C22.5794 41.1444 22.6915 41.6509 22.6915 42.2463ZM21.5292 42.2463C21.5292 41.8606 21.4718 41.5354 21.3571 41.2706C21.2441 41.0058 21.0844 40.805 20.8779 40.6681C20.6714 40.5313 20.4295 40.4629 20.1524 40.4629C19.8753 40.4629 19.6335 40.5313 19.427 40.6681C19.2205 40.805 19.0599 41.0058 18.9451 41.2706C18.8322 41.5354 18.7757 41.8606 18.7757 42.2463C18.7757 42.6319 18.8322 42.9571 18.9451 43.2219C19.0599 43.4867 19.2205 43.6875 19.427 43.8244C19.6335 43.9612 19.8753 44.0296 20.1524 44.0296C20.4295 44.0296 20.6714 43.9612 20.8779 43.8244C21.0844 43.6875 21.2441 43.4867 21.3571 43.2219C21.4718 42.9571 21.5292 42.6319 21.5292 42.2463Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M23.5407 44.976V39.5165H25.6799C26.0912 39.5165 26.4415 39.5956 26.731 39.7538C27.0205 39.9102 27.2411 40.1279 27.3929 40.4069C27.5465 40.6841 27.6232 41.004 27.6232 41.3666C27.6232 41.7291 27.5456 42.049 27.3903 42.3262C27.2349 42.6035 27.0099 42.8194 26.7151 42.974C26.4221 43.1286 26.0673 43.2059 25.6508 43.2059H24.2873V42.2809H25.4655C25.6861 42.2809 25.8679 42.2427 26.0109 42.1663C26.1556 42.0881 26.2633 41.9806 26.3339 41.8437C26.4062 41.7051 26.4424 41.5461 26.4424 41.3666C26.4424 41.1853 26.4062 41.0271 26.3339 40.8921C26.2633 40.7552 26.1556 40.6495 26.0109 40.5748C25.8661 40.4984 25.6826 40.4602 25.4602 40.4602H24.6871V44.976H23.5407Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M30.0934 44.976V39.5165H32.2327C32.6422 39.5165 32.9916 39.5903 33.2811 39.7378C33.5724 39.8835 33.7939 40.0906 33.9457 40.3589C34.0992 40.6255 34.176 40.9392 34.176 41.2999C34.176 41.6625 34.0983 41.9744 33.943 42.2356C33.7877 42.4951 33.5626 42.6941 33.2679 42.8327C32.9749 42.9713 32.6201 43.0406 32.2036 43.0406H30.7712V42.113H32.0182C32.2371 42.113 32.4189 42.0828 32.5636 42.0223C32.7084 41.9619 32.816 41.8713 32.8866 41.7504C32.959 41.6296 32.9952 41.4794 32.9952 41.2999C32.9952 41.1186 32.959 40.9658 32.8866 40.8414C32.816 40.717 32.7075 40.6228 32.561 40.5588C32.4162 40.4931 32.2336 40.4602 32.0129 40.4602H31.2398V44.976H30.0934ZM33.0217 42.4915L34.3693 44.976H33.1037L31.7852 42.4915H33.0217Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M35.9208 44.976H34.6923L36.5641 39.5165H38.0415L39.9107 44.976H38.6822L37.324 40.7641H37.2816L35.9208 44.976ZM35.844 42.8301H38.7457V43.7311H35.844V42.8301Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M39.6942 40.4682V39.5165H44.1474V40.4682H42.4874V44.976H41.3543V40.4682H39.6942Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M44.8808 44.976V39.5165H48.5345V40.4682H46.0272V41.7691H48.3465V42.7208H46.0272V44.0243H48.5451V44.976H44.8808Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
            <path
                d="M51.3681 44.976H49.4459V39.5165H51.3839C51.9293 39.5165 52.3989 39.6258 52.7925 39.8444C53.1861 40.0612 53.4888 40.3731 53.7006 40.7801C53.9142 41.1871 54.0209 41.674 54.0209 42.2409C54.0209 42.8096 53.9142 43.2983 53.7006 43.7071C53.4888 44.1158 53.1843 44.4295 52.7872 44.6481C52.3918 44.8667 51.9188 44.976 51.3681 44.976ZM50.5923 43.987H51.3204C51.6593 43.987 51.9444 43.9266 52.1756 43.8057C52.4086 43.6831 52.5833 43.4938 52.6998 43.2379C52.8181 42.9802 52.8772 42.6479 52.8772 42.2409C52.8772 41.8375 52.8181 41.5078 52.6998 41.2519C52.5833 40.996 52.4094 40.8076 52.1782 40.6868C51.947 40.566 51.6619 40.5055 51.3231 40.5055H50.5923V43.987Z"
                [attr.fill]="isHeader() ? '#fff' : '#000'" />
        </svg>

        <svg
            width="46"
            height="64"
            viewBox="0 0 46 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.4848 31.9701C31.3131 31.9701 38.4699 24.8134 38.4699 15.9851C38.4699 7.15676 31.3131 0 22.4848 0C13.6565 0 6.49976 7.15676 6.49976 15.9851C6.49976 24.8134 13.6565 31.9701 22.4848 31.9701ZM22.4848 27.9703C15.8656 27.9703 10.4996 22.6043 10.4996 15.9851C10.4996 9.36581 15.8656 3.99983 22.4848 3.99983C29.1041 3.99983 34.4701 9.36581 34.4701 15.9851C34.4701 22.6043 29.1041 27.9703 22.4848 27.9703ZM22.4848 8.53931L25.6414 13.6828L31.5086 12.1731L27.5922 19.6871H17.3773L13.4609 12.1731L19.3281 13.6828L22.4848 8.53931ZM17.47 21H27.4998V22.5672H17.47V21Z"
                [attr.fill]="isHeader() ? '#d1d1d1' : '#fff'" />
            <path
                d="M1.49976 45.0541V42.2633H6.36173V55.0764H3.23743V45.0541H1.49976Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M8.41493 48.4943C8.41493 46.4817 8.80108 44.8961 9.57337 43.7377C10.3574 42.5792 11.6153 42 13.3471 42C15.0789 42 16.331 42.5792 17.1033 43.7377C17.8873 44.8961 18.2793 46.4817 18.2793 48.4943C18.2793 50.5304 17.8873 52.1276 17.1033 53.2861C16.331 54.4445 15.0789 55.0238 13.3471 55.0238C11.6153 55.0238 10.3574 54.4445 9.57337 53.2861C8.80108 52.1276 8.41493 50.5304 8.41493 48.4943ZM15.3305 48.4943C15.3305 47.3125 15.2018 46.4056 14.9444 45.7737C14.6869 45.1301 14.1545 44.8084 13.3471 44.8084C12.5397 44.8084 12.0073 45.1301 11.7499 45.7737C11.4924 46.4056 11.3637 47.3125 11.3637 48.4943C11.3637 49.29 11.4105 49.9512 11.5041 50.4777C11.5977 50.9926 11.785 51.4139 12.0658 51.7415C12.3583 52.0574 12.7854 52.2154 13.3471 52.2154C13.9088 52.2154 14.33 52.0574 14.6109 51.7415C14.9034 51.4139 15.0965 50.9926 15.1901 50.4777C15.2837 49.9512 15.3305 49.29 15.3305 48.4943Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M19.865 48.4943C19.865 46.4817 20.2512 44.8961 21.0235 43.7377C21.8075 42.5792 23.0654 42 24.7972 42C26.529 42 27.7811 42.5792 28.5534 43.7377C29.3374 44.8961 29.7294 46.4817 29.7294 48.4943C29.7294 50.5304 29.3374 52.1276 28.5534 53.2861C27.7811 54.4445 26.529 55.0238 24.7972 55.0238C23.0654 55.0238 21.8075 54.4445 21.0235 53.2861C20.2512 52.1276 19.865 50.5304 19.865 48.4943ZM26.7806 48.4943C26.7806 47.3125 26.6519 46.4056 26.3945 45.7737C26.137 45.1301 25.6046 44.8084 24.7972 44.8084C23.9898 44.8084 23.4574 45.1301 23.1999 45.7737C22.9425 46.4056 22.8138 47.3125 22.8138 48.4943C22.8138 49.29 22.8606 49.9512 22.9542 50.4777C23.0478 50.9926 23.2351 51.4139 23.5159 51.7415C23.8084 52.0574 24.2355 52.2154 24.7972 52.2154C25.3589 52.2154 25.7801 52.0574 26.061 51.7415C26.3535 51.4139 26.5466 50.9926 26.6402 50.4777C26.7338 49.9512 26.7806 49.29 26.7806 48.4943Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M31.0694 45.4929C31.0694 44.5451 31.3385 43.8137 31.8768 43.2989C32.4268 42.784 33.1347 42.5266 34.0006 42.5266C34.8665 42.5266 35.5686 42.784 36.1069 43.2989C36.6451 43.8137 36.9143 44.5451 36.9143 45.4929C36.9143 46.4407 36.6451 47.1721 36.1069 47.6869C35.5686 48.2018 34.8665 48.4592 34.0006 48.4592C33.1347 48.4592 32.4268 48.2018 31.8768 47.6869C31.3385 47.1721 31.0694 46.4407 31.0694 45.4929ZM42.9874 42.7196L36.2297 55.0764H33.3336L40.0912 42.7196H42.9874ZM33.9831 44.2818C33.4214 44.2818 33.1405 44.6855 33.1405 45.4929C33.1405 46.2886 33.4214 46.6864 33.9831 46.6864C34.2522 46.6864 34.4628 46.587 34.6149 46.3881C34.7671 46.1891 34.8431 45.8907 34.8431 45.4929C34.8431 44.6855 34.5564 44.2818 33.9831 44.2818ZM39.4243 52.3032C39.4243 51.3553 39.6934 50.624 40.2317 50.1091C40.7699 49.5943 41.472 49.3368 42.3379 49.3368C43.2038 49.3368 43.9059 49.5943 44.4442 50.1091C44.9825 50.624 45.2516 51.3553 45.2516 52.3032C45.2516 53.251 44.9825 53.9823 44.4442 54.4972C43.9059 55.0121 43.2038 55.2695 42.3379 55.2695C41.472 55.2695 40.7699 55.0121 40.2317 54.4972C39.6934 53.9823 39.4243 53.251 39.4243 52.3032ZM42.3204 51.0921C42.0512 51.0921 41.8406 51.1915 41.6885 51.3904C41.5481 51.5894 41.4779 51.8936 41.4779 52.3032C41.4779 53.0989 41.7587 53.4967 42.3204 53.4967C42.5895 53.4967 42.8001 53.3973 42.9522 53.1983C43.1044 52.9994 43.1804 52.701 43.1804 52.3032C43.1804 51.9053 43.1044 51.6069 42.9522 51.408C42.8001 51.1974 42.5895 51.0921 42.3204 51.0921Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M3.58823 59.0695V62.3936C3.58823 62.9082 3.45197 63.3046 3.17946 63.5828C2.91127 63.8609 2.54792 64 2.08941 64C1.60927 64 1.22429 63.854 0.934478 63.5619C0.644663 63.2698 0.499756 62.8549 0.499756 62.3171H1.60278C1.60278 62.5211 1.64171 62.6764 1.71957 62.783C1.79743 62.885 1.9099 62.936 2.05697 62.936C2.19106 62.936 2.29488 62.8897 2.36841 62.7969C2.44195 62.7042 2.47871 62.5698 2.47871 62.3936V59.0695H3.58823Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M6.63858 64C6.21034 64 5.81671 63.8934 5.45769 63.6801C5.10299 63.4669 4.81967 63.1701 4.60771 62.79C4.40009 62.4052 4.29627 61.974 4.29627 61.4965C4.29627 61.019 4.40009 60.5902 4.60771 60.21C4.81967 59.8299 5.10299 59.5331 5.45769 59.3199C5.81671 59.1066 6.21034 59 6.63858 59C7.06681 59 7.45828 59.1066 7.81297 59.3199C8.172 59.5331 8.45316 59.8299 8.65646 60.21C8.86409 60.5902 8.96791 61.019 8.96791 61.4965C8.96791 61.974 8.86409 62.4052 8.65646 62.79C8.44884 63.1701 8.16767 63.4669 7.81297 63.6801C7.45828 63.8934 7.06681 64 6.63858 64ZM6.63858 62.9152C7.00193 62.9152 7.29174 62.7854 7.50802 62.5257C7.72863 62.2661 7.83893 61.923 7.83893 61.4965C7.83893 61.0654 7.72863 60.7223 7.50802 60.4673C7.29174 60.2077 7.00193 60.0779 6.63858 60.0779C6.2709 60.0779 5.97676 60.2054 5.75616 60.4604C5.53988 60.7153 5.43174 61.0607 5.43174 61.4965C5.43174 61.9277 5.53988 62.2731 5.75616 62.5327C5.97676 62.7877 6.2709 62.9152 6.63858 62.9152Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M12.3427 61.4478C12.6066 61.5081 12.8185 61.6495 12.9786 61.872C13.1386 62.0899 13.2187 62.3403 13.2187 62.6231C13.2187 63.0311 13.0846 63.3556 12.8164 63.5967C12.5525 63.8331 12.1827 63.9513 11.7069 63.9513H9.58516V59.0695H11.6355C12.0983 59.0695 12.4595 59.1831 12.7191 59.4103C12.9829 59.6375 13.1148 59.9458 13.1148 60.3352C13.1148 60.6226 13.0435 60.8614 12.9007 61.0515C12.7623 61.2415 12.5763 61.3737 12.3427 61.4478ZM10.6947 61.0445H11.4214C11.6031 61.0445 11.7415 61.0028 11.8366 60.9193C11.9361 60.8312 11.9859 60.7038 11.9859 60.5369C11.9859 60.37 11.9361 60.2425 11.8366 60.1544C11.7415 60.0663 11.6031 60.0223 11.4214 60.0223H10.6947V61.0445ZM11.5122 62.9917C11.6982 62.9917 11.841 62.9476 11.9404 62.8595C12.0443 62.7668 12.0962 62.6347 12.0962 62.4631C12.0962 62.2916 12.0421 62.1572 11.934 62.0598C11.8301 61.9624 11.6852 61.9138 11.4992 61.9138H10.6947V62.9917H11.5122Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M16.8852 64C16.5521 64 16.2537 63.942 15.9898 63.8261C15.7259 63.7102 15.514 63.5387 15.3539 63.3115C15.1982 63.0844 15.116 62.8108 15.1074 62.491H16.2883C16.3056 62.6718 16.364 62.8108 16.4635 62.9082C16.5629 63.0009 16.6927 63.0473 16.8528 63.0473C17.0171 63.0473 17.1469 63.0079 17.2421 62.9291C17.3372 62.8456 17.3848 62.732 17.3848 62.5883C17.3848 62.4678 17.3459 62.3681 17.268 62.2893C17.1945 62.2105 17.1015 62.1456 16.989 62.0946C16.8809 62.0436 16.7252 61.9856 16.5219 61.9207C16.2277 61.8234 15.9876 61.726 15.8016 61.6287C15.6156 61.5313 15.4556 61.3876 15.3215 61.1975C15.1874 61.0074 15.1204 60.7594 15.1204 60.4534C15.1204 59.9991 15.2739 59.6444 15.581 59.3894C15.8882 59.1298 16.2883 59 16.7814 59C17.2832 59 17.6876 59.1298 17.9947 59.3894C18.3018 59.6444 18.4662 60.0014 18.4878 60.4604H17.2875C17.2788 60.3027 17.2248 60.1799 17.1253 60.0918C17.0258 59.9991 16.8982 59.9527 16.7425 59.9527C16.6084 59.9527 16.5002 59.9921 16.418 60.0709C16.3359 60.1451 16.2948 60.2541 16.2948 60.3978C16.2948 60.5554 16.364 60.6783 16.5024 60.7663C16.6408 60.8544 16.8571 60.9495 17.1512 61.0515C17.4454 61.1581 17.6833 61.2601 17.8649 61.3574C18.0509 61.4548 18.211 61.5962 18.3451 61.7816C18.4792 61.9671 18.5462 62.2058 18.5462 62.4979C18.5462 62.7761 18.4792 63.0287 18.3451 63.2559C18.2153 63.4831 18.025 63.6639 17.7741 63.7983C17.5232 63.9328 17.2269 64 16.8852 64Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M20.3191 59.0695V61.9903C20.3191 62.2823 20.3861 62.5072 20.5202 62.6648C20.6543 62.8224 20.8511 62.9013 21.1107 62.9013C21.3702 62.9013 21.5692 62.8224 21.7076 62.6648C21.846 62.5072 21.9152 62.2823 21.9152 61.9903V59.0695H23.0247V61.9833C23.0247 62.4191 22.9382 62.7877 22.7652 63.089C22.5922 63.3904 22.3586 63.6175 22.0645 63.7705C21.7746 63.9235 21.4502 64 21.0912 64C20.7322 64 20.4099 63.9258 20.1244 63.7775C19.8433 63.6245 19.6205 63.3973 19.4561 63.096C19.2917 62.79 19.2096 62.4191 19.2096 61.9833V59.0695H20.3191Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M23.6157 61.5035C23.6157 61.0213 23.713 60.5925 23.9077 60.217C24.1023 59.8368 24.3727 59.5424 24.7187 59.3338C25.0691 59.1205 25.4649 59.0139 25.9061 59.0139C26.4468 59.0139 26.9096 59.1669 27.2946 59.4729C27.6796 59.7789 27.9369 60.1961 28.0667 60.7246H26.8469C26.7561 60.5206 26.6263 60.3653 26.4576 60.2587C26.2932 60.1521 26.1051 60.0987 25.8931 60.0987C25.5514 60.0987 25.2745 60.2262 25.0626 60.4812C24.8506 60.7362 24.7447 61.077 24.7447 61.5035C24.7447 61.93 24.8506 62.2707 25.0626 62.5257C25.2745 62.7807 25.5514 62.9082 25.8931 62.9082C26.1051 62.9082 26.2932 62.8549 26.4576 62.7483C26.6263 62.6416 26.7561 62.4863 26.8469 62.2823H28.0667C27.9369 62.8108 27.6796 63.2281 27.2946 63.5341C26.9096 63.8354 26.4468 63.9861 25.9061 63.9861C25.4649 63.9861 25.0691 63.8818 24.7187 63.6732C24.3727 63.4599 24.1023 63.1655 23.9077 62.79C23.713 62.4145 23.6157 61.9856 23.6157 61.5035Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M28.558 61.5035C28.558 61.0213 28.6553 60.5925 28.85 60.217C29.0446 59.8368 29.315 59.5424 29.661 59.3338C30.0114 59.1205 30.4072 59.0139 30.8484 59.0139C31.3891 59.0139 31.8519 59.1669 32.2369 59.4729C32.6219 59.7789 32.8793 60.1961 33.009 60.7246H31.7892C31.6984 60.5206 31.5686 60.3653 31.3999 60.2587C31.2355 60.1521 31.0474 60.0987 30.8354 60.0987C30.4937 60.0987 30.2169 60.2262 30.0049 60.4812C29.793 60.7362 29.687 61.077 29.687 61.5035C29.687 61.93 29.793 62.2707 30.0049 62.5257C30.2169 62.7807 30.4937 62.9082 30.8354 62.9082C31.0474 62.9082 31.2355 62.8549 31.3999 62.7483C31.5686 62.6416 31.6984 62.4863 31.7892 62.2823H33.009C32.8793 62.8108 32.6219 63.2281 32.2369 63.5341C31.8519 63.8354 31.3891 63.9861 30.8484 63.9861C30.4072 63.9861 30.0114 63.8818 29.661 63.6732C29.315 63.4599 29.0446 63.1655 28.85 62.79C28.6553 62.4145 28.558 61.9856 28.558 61.5035Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M34.798 60.0223V61.0097H36.2838V61.9277H34.798V62.9986H36.4785V63.9513H33.6885V59.0695H36.4785V60.0223H34.798Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M38.8469 64C38.5138 64 38.2153 63.942 37.9515 63.8261C37.6876 63.7102 37.4756 63.5387 37.3156 63.3115C37.1599 63.0844 37.0777 62.8108 37.069 62.491H38.2499C38.2672 62.6718 38.3256 62.8108 38.4251 62.9082C38.5246 63.0009 38.6544 63.0473 38.8144 63.0473C38.9788 63.0473 39.1086 63.0079 39.2037 62.9291C39.2989 62.8456 39.3465 62.732 39.3465 62.5883C39.3465 62.4678 39.3075 62.3681 39.2297 62.2893C39.1561 62.2105 39.0631 62.1456 38.9507 62.0946C38.8425 62.0436 38.6868 61.9856 38.4835 61.9207C38.1894 61.8234 37.9493 61.726 37.7633 61.6287C37.5773 61.5313 37.4172 61.3876 37.2832 61.1975C37.1491 61.0074 37.082 60.7594 37.082 60.4534C37.082 59.9991 37.2356 59.6444 37.5427 59.3894C37.8498 59.1298 38.2499 59 38.743 59C39.2448 59 39.6493 59.1298 39.9564 59.3894C40.2635 59.6444 40.4279 60.0014 40.4495 60.4604H39.2491C39.2405 60.3027 39.1864 60.1799 39.0869 60.0918C38.9874 59.9991 38.8598 59.9527 38.7041 59.9527C38.57 59.9527 38.4619 59.9921 38.3797 60.0709C38.2975 60.1451 38.2564 60.2541 38.2564 60.3978C38.2564 60.5554 38.3256 60.6783 38.464 60.7663C38.6025 60.8544 38.8187 60.9495 39.1129 61.0515C39.407 61.1581 39.6449 61.2601 39.8266 61.3574C40.0126 61.4548 40.1726 61.5962 40.3067 61.7816C40.4408 61.9671 40.5079 62.2058 40.5079 62.4979C40.5079 62.7761 40.4408 63.0287 40.3067 63.2559C40.177 63.4831 39.9866 63.6639 39.7358 63.7983C39.4849 63.9328 39.1886 64 38.8469 64Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
            <path
                d="M42.8387 64C42.5057 64 42.2072 63.942 41.9433 63.8261C41.6795 63.7102 41.4675 63.5387 41.3075 63.3115C41.1518 63.0844 41.0696 62.8108 41.0609 62.491H42.2418C42.2591 62.6718 42.3175 62.8108 42.417 62.9082C42.5165 63.0009 42.6462 63.0473 42.8063 63.0473C42.9707 63.0473 43.1004 63.0079 43.1956 62.9291C43.2908 62.8456 43.3383 62.732 43.3383 62.5883C43.3383 62.4678 43.2994 62.3681 43.2215 62.2893C43.148 62.2105 43.055 62.1456 42.9425 62.0946C42.8344 62.0436 42.6787 61.9856 42.4754 61.9207C42.1812 61.8234 41.9412 61.726 41.7552 61.6287C41.5692 61.5313 41.4091 61.3876 41.275 61.1975C41.1409 61.0074 41.0739 60.7594 41.0739 60.4534C41.0739 59.9991 41.2275 59.6444 41.5346 59.3894C41.8417 59.1298 42.2418 59 42.7349 59C43.2367 59 43.6411 59.1298 43.9482 59.3894C44.2554 59.6444 44.4197 60.0014 44.4414 60.4604H43.241C43.2324 60.3027 43.1783 60.1799 43.0788 60.0918C42.9793 59.9991 42.8517 59.9527 42.696 59.9527C42.5619 59.9527 42.4538 59.9921 42.3716 60.0709C42.2894 60.1451 42.2483 60.2541 42.2483 60.3978C42.2483 60.5554 42.3175 60.6783 42.4559 60.7663C42.5943 60.8544 42.8106 60.9495 43.1048 61.0515C43.3989 61.1581 43.6368 61.2601 43.8185 61.3574C44.0045 61.4548 44.1645 61.5962 44.2986 61.7816C44.4327 61.9671 44.4998 62.2058 44.4998 62.4979C44.4998 62.7761 44.4327 63.0287 44.2986 63.2559C44.1688 63.4831 43.9785 63.6639 43.7276 63.7983C43.4768 63.9328 43.1805 64 42.8387 64Z"
                [attr.fill]="isHeader() ? '#000' : '#fff'" />
        </svg>
    </div>
</a>
