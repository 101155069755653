import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { MetaTags } from '@shared/meta-tags/meta-tags.type';

@Injectable()
export class MetaTagsService {
    private readonly titleService = inject(Title);
    private readonly metaTagService = inject(Meta);
    public setMetaTags(metaTags: MetaTags | undefined): Observable<boolean> {
        const tags = {
            'og:type': 'website',
            ...metaTags,
        };

        Object.entries(tags).forEach(([key, content]) => {
            if (!content) {
                return;
            }
            if (key === 'title') {
                this.titleService.setTitle(content);
            }
            this.metaTagService.updateTag({ name: key, content });
        });

        return of(true);
    }
}
