[
    {
        "slug": "senior-typescript-fullstack-developer-1",
        "position": "developers",
        "title": "Senior TypeScript Full-stack Developer",
        "stack": ["Remote"],
        "requiredSkills": "4+ years of experience of JS/TS, experience with NodeJS, Angular, Intermediate English",
        "weOffer": "Competitive salary, Social package, Own educational platform with courses, training programs, certifications.",
        "description": "We are looking for a MIDDLE/SENIOR JS/TS DEVELOPER to join the Kharkiv office on a full-time basis. The position is remote with future relocation to Europe.",
        "responsibilities": [
            "Implementing new features and delivering them to production",
            "Designing and implementing core services using modern technologies and practices",
            "Daily communication with Product owner and Architect",
            "Making impact on products and technical decisions",
            "Providing support to services in production"
        ],
        "requirements": [
            "4+ years of experience of JS TS, NodeJS",
            "Professional programming experience in SOLID, OOP, Software Design Pattern, client-server architecture",
            "Willing to work on features as a whole (including frontend and backend parts)",
            "Experience with SQL",
            "Experience with Docker — Experience with NoSQL (Redis, Aerospike), SingleStore",
            "Experience in writing tests (unit testing or functional testing, instrumentation, and test harnesses) — Intermediate English"
        ],
        "niceToHave": [
            "Professional programming experience in Redux, Jest, Material UI",
            "Experience in decomposing technical tasks and defining level of effort for complex services",
            "Deep knowledge of concurrency, resiliency, caching",
            "Expertise in building highly scalable, fault-tolerant, distributed service",
            "Experience with reactive programming"
        ],
        "metaTags": {
            "title": "JSDaddy.io - Senior TypeScript Full-stack Developer Position",
            "description": "Join JSDaddy.io as a Senior TypeScript Full-stack Developer. Work remotely with future relocation to Europe. Required skills include JS/TS, NodeJS, Angular, SQL, Docker, and more.",
            "keywords": "JSDaddy, career, senior developer, TypeScript, full-stack developer, NodeJS, Angular, React, JavaScript, MongoDB, mySQL, PostgreSQL, remote job, software development",
            "og:url": "https://jsdaddy.io/career/senior-typescript-fullstack-developer-1",
            "og:title": "JSDaddy.io - Senior TypeScript Full-stack Developer Position",
            "og:description": "Join JSDaddy.io as a Senior TypeScript Full-stack Developer. Work remotely with future relocation to Europe. Required skills include JS/TS, NodeJS, Angular, SQL, Docker, and more."
        }
    },
    {
        "slug": "sales-1",
        "position": "sales",
        "title": "Sales Manager",
        "stack": ["Remote"],
        "requiredSkills": "Bach. degree, 2+ yrs B2B lead gen exp., successful campaigns, strong analytics/comm., adaptable, passion for leads.",
        "weOffer": "Competitive salary, Social package, Own educational platform with courses, training programs, certifications.",
        "description": "We are looking for an experienced Lead Generation Manager to join our dynamic team. As the Lead Generation Manager, you will be responsible for developing and implementing strategies to generate leads and improve our overall customer acquisition process.",
        "responsibilities": [
            "Develop and implement lead generation strategies to drive growth in new business and customer acquisition",
            "Create and manage targeted marketing campaigns to generate leads across various channels (e.g. email, social media, search engine marketing, etc.)",
            "Monitor and analyze lead generation metrics to improve the effectiveness of campaigns and optimize lead quality",
            "Work closely with sales and marketing teams to develop and execute lead nurturing programs to increase conversion rates",
            "Collaborate with the product team to develop effective messaging and positioning that resonates with target customers",
            "Research and stay up-to-date on industry trends and best practices for lead generation",
            "Manage lead databases and ensure data quality and accuracy"
        ],
        "requirements": [
            "Bachelor's degree in marketing, business administration or a related field",
            "2+ years of experience in lead generation, preferably in a B2B SaaS environment",
            "Proven track record of developing and executing successful lead generation campaigns",
            "Strong analytical skills and ability to analyze and interpret data to drive insights and make data-driven decisions",
            "Excellent communication and interpersonal skills",
            "Ability to work independently and as part of a team in a fast-paced environment",
            "If you are a driven and motivated individual with a passion for lead generation, we want to hear from you! Apply today to join our team as a Lead Generation Manager."
        ],
        "niceToHave": [
            "Experience with CRM software (e.g., Salesforce, HubSpot) for lead management and tracking",
            "Knowledge of inbound marketing strategies and tactics",
            "Familiarity with social selling techniques and leveraging social media platforms for lead generation",
            "Understanding of sales automation tools and technologies",
            "Previous experience in the SaaS industry",
            "Proficiency in additional languages for international sales",
            "Certifications or training in sales methodologies (e.g., SPIN Selling, Challenger Sale)",
            "Experience in conducting market research and identifying target market segments",
            "Knowledge of competitor analysis and market positioning strategies",
            "Ability to develop and deliver persuasive sales presentations"
        ],
        "metaTags": {
            "title": "JSDaddy.io - Sales Manager Position",
            "description": "Join JSDaddy.io as a Sales Manager. Develop and implement strategies to generate leads, improve customer acquisition, and drive business growth. Apply now to be part of our dynamic team.",
            "keywords": "JSDaddy, career, sales manager, lead generation, customer acquisition, business growth, remote job, sales strategies",
            "og:url": "https://jsdaddy.io/career/sales-1",
            "og:title": "JSDaddy.io - Sales Manager Position",
            "og:description": "Join JSDaddy.io as a Sales Manager. Develop and implement strategies to generate leads, improve customer acquisition, and drive business growth. Apply now to be part of our dynamic team."
        }
    }
]
