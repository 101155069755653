<jsdaddy-form-sending-dialog />
<form [formGroup]="form">
    @for (step of steps; track step.formControlName; let i = $index) {
        @if (currentStep() === i || !withSteps()) {
            <div class="column">
                @if (withSteps()) {
                    <div class="row">
                        <span>{{ (i | contactForm) + '/' }}</span>
                        <span [class.o-25]="i !== steps.length - 1">{{
                            steps.length - 1 | contactForm
                        }}</span>
                        @if (currentStep()) {
                            <span aria-hidden="true" (click)="back()" class="pointer"
                                >&nbsp;-&nbsp;BACK</span
                            >
                        }
                    </div>
                }

                <jsdaddy-input
                    [isDark]="withSteps()"
                    [formControlName]="step.formControlName"
                    (labelClick)="next()"
                    [label]="withSteps() | contactFormLabel: i : steps.length"
                    [placeholder]="step.placeholder"
                    [validators]="step.validators"
                    [isTextarea]="step.isTextarea || false" />
            </div>
        }
    }
    @if (!withSteps()) {
        <jsdaddy-button
            title="Send Message"
            [type]="buttonTypePrimaryYellow"
            (click)="sendEmail()" />
    }
</form>
