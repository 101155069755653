import { CareerDetailsSection } from '../career-details.section';

export class CareerDetailsContent extends CareerDetailsSection {
    public constructor(
        public readonly texts: string[],
        public override readonly title: string
    ) {
        super(title);
    }
}
