import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular-ivy';
import { environment } from './environments/environment';

if (environment.sentry.env !== 'dev') {
    init({
        dsn: 'https://8ea5ff56d5d64154bc9ce723a64fc1d9@o250244.ingest.us.sentry.io/4505120721338368',
        integrations: [browserTracingIntegration(), replayIntegration()],
        environment: environment.sentry.env,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
// eslint-disable-next-line no-console
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
