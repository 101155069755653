<div [style.height]="half() ? this.height + 'px' : '100%'">
    <div
        [style.--bg-image]="
            image()?.toString() || '1'
                | asset: 'shared/show-cases/' + showCase()?.assetsFolder : true
                | imageUrl
        "
        [class]="[showCase()?.type, 'column pos-rel inside'].join(' ')">
        <img
            height="100%"
            width="100%"
            [alt]="showCase()?.type"
            #imageRef
            [ngSrc]="showCase()?.type | frame: half() && showCase()?.type === webShowCaseType"
            fill />
    </div>
</div>
