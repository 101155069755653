import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
    output,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import servicesJson from 'src/assets/json/services.json';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-service-page-info[width][isShowDropdown]',
    standalone: true,
    imports: [RouterLinkWithHref, SectionWrapperComponent, BorderButtonComponent, AnchorLabelPipe],
    templateUrl: './service-page-info.component.html',
    styleUrl: './service-page-info.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePageInfoComponent {
    public width = input.required<number>();
    public isShowDropdown = input.required<boolean>();
    public closeDropdown = output();
    public readonly contactUs = `/${AppPath.CONTACT_US}`;
    public readonly services = `/${AppPath.SERVICES}`;
    public readonly servicesJson = servicesJson;
    public readonly marginRightOfDiscussBlockStart = 112;
    public readonly marginRightOfDiscussBlockEnd = 212;

    @HostBinding('style.width.px')
    public get blockWidth(): number {
        return this.width();
    }

    public closeDropdownEmit(): void {
        this.closeDropdown.emit();
    }
}
