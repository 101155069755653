<jsdaddy-section-wrapper [black]="true">
    <div class="container">
        @for (position of ['left-top', 'left-bottom', 'right-bottom']; track position) {
            <img
                alt="Obj plus"
                [class]="['obj-plus', 'obj-plus-' + position].join(' ')"
                width="1"
                height="1"
                [ngSrc]="'obj-plus-2' | asset: assetPathShared" />
        }
        <div class="row ai-c main">
            <div class="column left-side">
                <span class="h1-text full-white">Oops.</span>
                <span class="h2-text yellow">Sorry we can't find that page!</span>
                <span class="text full-white"
                    >Either something went wrong or the page doesn't exist anymore....</span
                >
                <jsdaddy-button
                    [link]="homePath"
                    [type]="buttonPrimaryYellow"
                    title="Back To Home Page" />
            </div>
            <div class="row jc-c">
                <img [ngSrc]="'404' | asset: 'not-found' : true" width="1" height="1" alt="404" />
            </div>
        </div>
    </div>
</jsdaddy-section-wrapper>
