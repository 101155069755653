[
    {
        "name": "Ivan Potemkin",
        "linkedin": "https://www.linkedin.com/in/ivan-potemkin-73606439",
        "position": "Head of TVC CGI Department – Bazelevs Group",
        "image": "ivanpotemkin",
        "text": "The JSDaddy IT team has truly exceeded expectations with their exceptional work on both the frontend and backend development of our website. Their commitment to providing top-notch client service sets them apart. Throughout the entire process, they have been responsive, accommodating, and proactive in addressing any concerns or requests. Their dedication to ensuring our satisfaction has been evident in every interaction.",
        "subText": "Kudos to Igor and the entire JSDaddy team for their outstanding work and unparalleled client service."
    },
    {
        "name": "Oleg Semiglazov",
        "linkedin": "https://www.linkedin.com/in/olegsemiglazov",
        "position": "CTO, Uran Company",
        "image": "olegsemiglazov",
        "text": "I had worked with JSDaddy for one year. During this period, they have provided consulting services in the field of IT and technical assessment of future employees.",
        "subText": "Distinctive qualities of this company are responsibility, professionalism, reliability and response rate. I recommend them."
    },
    {
        "name": "Kostiantyn Nikolaiev",
        "linkedin": "https://www.linkedin.com/in/kostiantynnikolaiev",
        "position": "CEO, Combo 21",
        "image": "kostiantynnikolaiev",
        "text": "JSDaddy demonstrated a well organized workflow, the communication was smooth and constructive. All the tasks were fulfilled timely and scrupulously. All our requirements were met to the smallest detail.",
        "subText": "They are real hard workers and passionate developers."
    },
    {
        "name": "Mark Divitt",
        "linkedin": "https://www.linkedin.com/in/mark-divitt-00a12a",
        "position": "Director of Software Development Rantizo",
        "image": "mark-divitt-00a12a",
        "text": "Working with JSDaddy was really easy. Developers are highly professional and we were able to quickly setup dedicated team for our project, build development process based on best practices and finished project on expected dates.",
        "subText": "Great guys, would not hesitate to work with them again."
    },
    {
        "name": "Yanis Onkes",
        "linkedin": "https://www.linkedin.com/in/yanis-onkes",
        "position": "CTO, Bazelevs Group",
        "image": "yanis-onkes",
        "text": "JSDaddy has provided an audit service in the field of IT for my company.",
        "subText": "About this service, I can say the following: professional, reliable, responsible and available any time of the day. I definitely recommend them."
    },
    {
        "name": "Hong Qi Yu",
        "linkedin": "https://www.linkedin.com/in/hqy",
        "position": "CEO, Tokenize",
        "image": "hqy",
        "text": "JSDaddy is a great team who is able to take the challenge to complete complex project in limited timeline.",
        "subText": ""
    }
]
