import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ArrowButtonComponent } from '@shared/arrow-button/arrow-button.component';
import { ArrowButtonDirectionEnum } from '@shared/arrow-button/arrow-button.enum';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { SliderEnableDisablePipe } from '@shared/slider-enable-disable/slider-enable-disable.pipe';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';
import { HomeAssetPath } from '../home.path';
import { WhyChooseCardComponent } from './why-choose-card/why-choose-card.component';
import { WhyChooseCard } from './why-choose-card/why-choose-card.type';
import { BaseSliderComponent } from '@shared/slider-enable-disable/base-slider.component';

@Component({
    selector: 'jsdaddy-choose-us',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        RouterLinkWithHref,
        SliderEnableDisablePipe,
        ButtonComponent,
        ArrowButtonComponent,
        WhyChooseCardComponent,
        HeaderTitleComponent,
        AssetPipe,
        NgOptimizedImage,
    ],
    providers: [SliderEnableDisableService],
    templateUrl: './choose-us.component.html',
    styleUrl: './choose-us.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseUsComponent extends BaseSliderComponent {
    public slidesElement = viewChild<string, ElementRef<HTMLElement>>('slides', {
        read: ElementRef,
    });

    public slide = viewChild<string, ElementRef<HTMLElement>>('slide', { read: ElementRef });

    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly buttonTypePrimaryYellow = ButtonType.PrimaryYellow;
    public readonly backArrowDirection = ArrowButtonDirectionEnum.BACK;
    public left = 0;
    public readonly assetPath = HomeAssetPath.CHOOSE_US;
    public cards: WhyChooseCard[] = [
        {
            title: 'Tailor made',
            icon: 'tailor-made',
            description:
                'By utilizing the latest up-to-date technologies such as Angular or Flutter, our development company delivers exceptional custom software solutions according to your business requirements. Any difficulties or suggestions that may appear during the development process will be resolved and improved by our web and mobile developers.',
        },
        {
            title: 'Support',
            icon: 'support',
            description:
                'The major benefit you get with custom business applications is an efficient and reliable technical support plan. You will have full access to technical support of our development team that was involved in the development process of your Web or Hybrid application.',
        },
        {
            title: 'Maintenance',
            icon: 'maintenance',
            description:
                'With custom Angular or Flutter etc. application development your software is maintained for as long as you require. With off-the-shelf software your business is at the mercy of the software development company you are purchasing from.',
        },
        {
            title: 'Integration',
            icon: 'integration',
            description:
                'Our top-notch developers can make your custom software have integration with every known system or service you need. With an aid of JavaScript, TypeScript or Dart your business can enjoy the benefits of operating numerous services through one application.',
        },
        {
            title: 'Cost-effectiveness',
            icon: 'costs',
            description:
                'According to our research - Angular and Flutter based applications give us ability to cut the cost by 29%, comparing to other technologies that are produced on the market like React or ReactNative.',
        },
        {
            title: 'Quality',
            icon: 'quality',
            description:
                'Our development team creates stable and scalable applications using best programming practices and approaches which are listed in our internal style guide which we follow. Using test coverage is a part of our daily work.',
        },
    ];
}
