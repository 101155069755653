<div class="arrow-button" [class]="[direction(), type()].join(' ')" [class.disabled]="!enabled()">
    <img
        width="25"
        height="25"
        [style.transform]="rotateDeg() | transformRotate"
        [class.disabled]="!enabled()"
        class="back black"
        [ngSrc]="'arrow-back-black' | asset: assetPath"
        alt="Arrow black back" />
    <img
        width="25"
        height="25"
        [style.transform]="rotateDeg() | transformRotate"
        class="forward black"
        [class.disabled]="!enabled()"
        [ngSrc]="'arrow-forward-black' | asset: assetPath"
        alt="Arrow forward black" />
    <img
        width="25"
        height="25"
        [style.transform]="rotateDeg() | transformRotate"
        [class.disabled]="!enabled()"
        class="back white"
        [ngSrc]="'arrow-back-white' | asset: assetPath"
        alt="Arrow back white" />
    <img
        width="25"
        height="25"
        [style.transform]="rotateDeg() | transformRotate"
        [class.disabled]="!enabled()"
        class="forward white"
        [ngSrc]="'arrow-forward-white' | asset: assetPath"
        alt="Arrow forward white" />
</div>
