import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imageUrl',
    standalone: true,
})
export class ImageUrlPipe implements PipeTransform {
    public transform(url: string): string {
        return `url(${url})`;
    }
}
