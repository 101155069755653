<div class="wrapper">
    <div class="content column jc-sb">
        <img
            aria-hidden="true"
            class="close-btn"
            (click)="closeReadyForDiscuss()"
            width="35"
            height="35"
            [ngSrc]="'close-btn' | asset: assetPathShared"
            alt="Close button" />
        <span class="h1-text heading"><span> Ready to discuss</span> your project?</span>
        <div class="container-contact-form">
            <div class="wrapper-contact-form">
                <jsdaddy-contact-form />
            </div>
            <img
                class="background"
                width="529"
                height="725"
                [ngSrc]="'background' | asset: assetPathContactForm : true"
                alt="Background" />
        </div>
    </div>
</div>
