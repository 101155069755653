import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contactForm',
    standalone: true,
})
export class ContactFormPipe implements PipeTransform {
    public transform(index: number): string {
        return `0${index + 1}`;
    }
}
