import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'visibility',
    standalone: true,
})
export class VisibilityPipe implements PipeTransform {
    public transform(visible: boolean): string {
        return visible ? 'visible' : 'hidden';
    }
}
