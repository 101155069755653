import { isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { BodyStylesService } from '@shared/body-styles/body-styles.service';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { GridComponent } from '@shared/grid/grid.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { AppPath } from '@shared/routes/app-routing.enum';
import { UpworkSvgComponent } from '@shared/upwork-svg/upwork-svg.component';
import { ReadyForDiscussService } from '@header/ready-for-discuss/ready-for-discuss.service';
import { ReadyForDiscussComponent } from '@header/ready-for-discuss/ready-for-discuss.component';
import { HomeAssetPath } from '../home.path';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-banner',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        ButtonComponent,
        AssetPipe,
        ReadyForDiscussComponent,
        ImageUrlPipe,
        GridComponent,
        UpworkSvgComponent,
        AnchorLabelPipe,
    ],
    providers: [BodyStylesService],
    templateUrl: './banner.component.html',
    styleUrl: './banner.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
    public readonly buttonTypePrimrayYellow = ButtonType.PrimaryYellow;
    public readonly buttonTypeGhost = ButtonType.Ghost;
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly assetPathShared = HomeAssetPath.SHARED;
    private readonly readyForDiscussService = inject(ReadyForDiscussService);
    private readonly platformId = inject<string>(PLATFORM_ID);

    public isShowContactForm = false;

    public readonly libraries: { firstName: string; secondName: string; nameGirHubName: string }[] =
        [
            {
                firstName: '({Ngx Copypaste})',
                secondName: 'Ngx-copypaste',
                nameGirHubName: 'ngx-copypaste',
            },
            {
                firstName: '({Ngx Loader Indicator})',
                secondName: 'Ngx-loader-indicator',
                nameGirHubName: 'ngx-loader-indicator',
            },
            { firstName: '({Ngx Mask})', secondName: 'Ngx-mask', nameGirHubName: 'ngx-mask' },
        ];

    public showContactForm(): void {
        this.readyForDiscussService.showHideReadyForDiscuss(true);
        if (isPlatformServer(this.platformId)) {
            return;
        }
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
    public showCircleMore(mouseEvent: MouseEvent): void {
        mouseEvent.stopPropagation();
    }
}
