import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CareerDetailsSection } from './career-details.section';
import { CareerDetailsTitles } from './career-details.titles';

@Injectable()
export class CareerDetailsService {
    private sectionSource$$ = new BehaviorSubject<string>('');

    public currentSection$ = this.sectionSource$$.asObservable();

    public sections: CareerDetailsSection[] = [
        new CareerDetailsSection(CareerDetailsTitles.PROJECT_DETAILS),
        new CareerDetailsSection(CareerDetailsTitles.RESPONSIBILITIES),
        new CareerDetailsSection(CareerDetailsTitles.REQUIREMENTS),
        new CareerDetailsSection(CareerDetailsTitles.NICE_TO_HAVE),
        new CareerDetailsSection(CareerDetailsTitles.SEND_YOUR_CV),
    ];

    public getSection(careerDetailsTitle: CareerDetailsTitles): CareerDetailsSection | undefined {
        return this.sections.find(({ title }) => title === careerDetailsTitle);
    }

    public changeSection(section: string): void {
        this.sectionSource$$.next(section);
    }
}
