import { isPlatformServer } from '@angular/common';
// type-coverage:ignore-next-line
import { DestroyRef, ElementRef, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, delay, filter, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ReadyForDiscussService {
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly destroyRef = inject(DestroyRef);

    private readyForDiscussClass$$ = new BehaviorSubject('close-ready-for-discuss');
    public readyForDiscussClass$: Observable<string> = this.readyForDiscussClass$$.asObservable();

    private timeOfAnimationReadyForDiscuss = 0;

    private isShowReadyForDiscuss$$ = new BehaviorSubject(false);
    public isShowReadyForDiscuss$: Observable<boolean> =
        this.isShowReadyForDiscuss$$.asObservable();

    public showHideReadyForDiscuss(value: boolean): void {
        this.isShowReadyForDiscuss$$.next(value);
    }

    public subscribeToIsShowReadyForDiscuss(
        readyForDiscussContainer: ElementRef<HTMLDivElement> | undefined
    ): void {
        if (isPlatformServer(this.platformId) || !readyForDiscussContainer) {
            return;
        }
        const computedStyles: CSSStyleDeclaration | null | undefined = getComputedStyle(
            readyForDiscussContainer?.nativeElement
        );
        this.timeOfAnimationReadyForDiscuss =
            parseFloat(computedStyles?.getPropertyValue('transition-duration')) * 1000;
        this.isShowReadyForDiscuss$
            .pipe(
                filter(Boolean),
                delay(this.timeOfAnimationReadyForDiscuss),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                this.readyForDiscussClass$$.next('open-ready-for-discuss');
            });
        this.isShowReadyForDiscuss$
            .pipe(
                filter((val) => !val),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                this.readyForDiscussClass$$.next('close-ready-for-discuss');
            });
    }
}
