import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    input,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { ArrowButtonComponent } from '@shared/arrow-button/arrow-button.component';
import { ArrowButtonDirectionEnum } from '@shared/arrow-button/arrow-button.enum';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { ChipComponent } from '@libraries/chip/chip.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { AppPath } from '@shared/routes/app-routing.enum';
import { RowBlackWhiteWrapperComponent } from '@shared/row-black-white-wrapper/row-black-white-wrapper.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ImageWithFrameComponent } from '@shared/show-cases/image-with-frame/image-with-frame.component';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { CaseAssetPath } from '../case.path';
import { IndentPipe } from '@shared/indent/indent.pipe';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { SliderEnableDisableService } from '@shared/slider-enable-disable/slider-enable-disable.service';
import { SliderEnableDisablePipe } from '@shared/slider-enable-disable/slider-enable-disable.pipe';
import { BaseSliderComponent } from '@shared/slider-enable-disable/base-slider.component';

@Component({
    selector: 'jsdaddy-case-overview[showCase]',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        AssetPipe,
        ImageUrlPipe,
        ChipComponent,
        ArrowButtonComponent,
        ImageWithFrameComponent,
        ButtonComponent,
        SectionWrapperComponent,
        HeaderTitleComponent,
        RowBlackWhiteWrapperComponent,
        IndentPipe,
        SliderEnableDisablePipe,
    ],
    providers: [SliderEnableDisableService],
    templateUrl: './case-overview.component.html',
    styleUrl: './case-overview.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseOverviewComponent extends BaseSliderComponent {
    public showCase = input.required<ShowCase | undefined>();
    public assetPath = CaseAssetPath.OVERVIEW;
    public assetPathSharedShowCases = SharedAssetPath.SHOW_CASES;
    public slidesElement = viewChild<string, ElementRef<HTMLElement>>('slides', {
        read: ElementRef,
    });
    public slide = viewChild<string, ElementRef<HTMLElement>>('slide', { read: ElementRef });

    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly backDirection = ArrowButtonDirectionEnum.BACK;
    public readonly primaryYellowButtonType = ButtonType.PrimaryYellow;
}
