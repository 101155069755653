<div
    class="plus plus-right hide-tablet hide-mobile hide-tablet"
    [style.background-image]="'obj-plus-slider-right' | asset: assetPath | imageUrl"></div>
<div
    class="plus plus-left hide-tablet hide-mobile hide-tablet"
    [style.background-image]="'obj-plus-slider-left' | asset: assetPath | imageUrl"></div>
<jsdaddy-header-title
    class="row"
    [needStar]="true"
    [black]="true"
    title="Technologies"
    subtitle="JS DADDY" />
<div class="slider-wrapper ais-c">
    <jsdaddy-technologies-slider [technologies]="technologies" />
</div>
