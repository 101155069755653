import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { CareerAssetPath } from '../career.path';

@Component({
    selector: 'jsdaddy-career-life-at',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ImageUrlPipe,
        AssetPipe,
        SectionWrapperComponent,
        HeaderTitleComponent,
        NgOptimizedImage,
    ],
    templateUrl: './career-life-at.component.html',
    styleUrl: './career-life-at.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerLifeAtComponent {
    public assetPath = CareerAssetPath.LIFE;
}
