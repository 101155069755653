import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'email',
    standalone: true,
})
export class EmailPipe implements PipeTransform {
    public transform(email: string): string {
        return `mailto:${email}`;
    }
}
