import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contactFormLabel',
    standalone: true,
})
export class ContactFormLabelPipe implements PipeTransform {
    public transform(withSteps: boolean, index: number, stepsLength: number): string | null {
        return !withSteps ? null : index < stepsLength - 1 ? 'NEXT' : 'SEND MESSAGE';
    }
}
