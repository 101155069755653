import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformRotate',
    standalone: true,
})
export class TransformRotatePipe implements PipeTransform {
    public transform(deg: number): string {
        return `rotate(${deg}deg)`;
    }
}
