<div class="service-figure"></div>
<div class="left-plus"></div>
<div class="top-plus"></div>
<div class="pos-rel services-text">
    <div class="row ai-start">
        <jsdaddy-header-title
            [needStar]="true"
            title="Services"
            subtitle="SOFTWARE DEVELOPMENT"
            [isTitleRightSide]="true"
            [isSubtitleMoreThanText]="true" />
    </div>
</div>
@for (service of services; track service.slug; let i = $index; let last = $last) {
    <div class="service-item" [style]="{ 'align-items': i > 1 ? 'start' : 'end' }">
        <div [style]="{ width: i === 2 && '100%' }" class="divider bg-white"></div>
        @if (!last) {
            <ng-container *ngTemplateOutlet="content; context: { service, i }"></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    discover;
                    context: { $implicit: 'hide-desktop hide-tablet', slug: service.slug }
                " />
        }
        @if (last) {
            <div class="last-service">
                <ng-container
                    *ngTemplateOutlet="
                        content;
                        context: { service, bottomBorder: true, i }
                    "></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        discover;
                        context: { $implicit: 'hide-desktop hide-tablet', slug: service.slug }
                    " />
                <div class="divider bg-white hide-tablet hide-desktop"></div>
                <jsdaddy-button
                    [link]="contactUsPath"
                    class="button"
                    [type]="ghostWhiteButton"
                    title="Want in? Connect with us!" />
            </div>
        }
    </div>
}
<div class="ellipse"></div>

<ng-template #content let-service="service" let-i="i" let-bottomBorder="bottomBorder">
    <div class="content">
        <p class="index text full-white">0{{ i + 1 }}</p>
        <div
            class="row"
            [class.bottom-border]="bottomBorder"
            [class.bottom-border-mobile]="bottomBorder">
            <div class="left">
                <span class="full-white h3-text">{{ service.title }}</span>
                <ng-container
                    *ngTemplateOutlet="
                        discover;
                        context: { $implicit: 'hide-mobile', slug: service.slug }
                    "></ng-container>
            </div>
            <p class="service-text text full-white">{{ service.shortDescription }}</p>
        </div>
    </div>
</ng-template>
<ng-template #discover let-class let-slug="slug">
    <a
        [routerLink]="[servicesLink, slug]"
        [attr.aria-label]="'Read more about ' + slug + 'service'">
        <div [class]="'discover ' + class">
            <div class="icon"></div>
            <p aria-label="Read more" class="discover-text text full-white">Discover mode</p>
        </div>
    </a>
</ng-template>
