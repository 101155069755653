@if (showCases()) {
    @if (themeScheme.needStars) {
        <img
            [ngSrc]="'obj-plus-top' | asset: assetPath"
            width="9"
            height="136"
            alt="Stars top"
            class="plus-top hide-mobile hide-tablet" />
        <img
            [ngSrc]="'obj-plus-center' | asset: assetPath"
            class="plus-bottom hide-mobile"
            alt="Stars bottom"
            width="140"
            height="98" />
        <img
            [ngSrc]="'obj-plus-center' | asset: assetPath"
            class="plus-center hide-mobile hide-tablet"
            width="140"
            alt="Stars center"
            height="98" />
    }
    <div class="pos-rel header-title row jc-sb">
        <jsdaddy-header-title
            [black]="themeScheme.isTitleBlack"
            [title]="title()"
            [needStar]="true"
            [isSubtitleMoreThanText]="isSubtitleMoreThanText()"
            [subtitle]="subtitle()" />
        <ng-container *ngTemplateOutlet="showAll; context: { $implicit: 'hide-desktop' }" />
    </div>
    @for (showCase of showCases(); track showCase?.slug; let i = $index; let first = $first) {
        <ng-container>
            <div class="column">
                <div
                    [class]="
                        [
                            'divider',
                            themeScheme.dividerColorStyle,
                            first ? 'first ais-end' : '',
                        ].join(' ')
                    "></div>
                <ng-container
                    *ngTemplateOutlet="
                        showCaseRef;
                        context: { $implicit: showCase, i, first }
                    "></ng-container>
                <div [class]="['divider', themeScheme.dividerColorStyle].join(' ')"></div></div
        ></ng-container>
    }
    @if (themeScheme.needStars) {
        <div class="row jc-fe and-others">
            <div class="column jc-sb">
                <p [class]="[themeScheme.textColorStyle, 'uppercase ls-25 title'].join(' ')">
                    {{ showCasesLength | showCasesListOthers }}<br />
                    others...
                </p>
                <span
                    [class]="
                        [
                            themeScheme.textColorStyle,
                            'uppercase want-to-discover h3-text pos-rel',
                        ].join(' ')
                    ">
                    Want to be<br />
                    a major<br />
                    client?
                    <img
                        [ngSrc]="'obj-plus-center' | asset: assetPath"
                        class="plus-bottom hide-desktop hide-tablet"
                        alt="Stars bottom"
                        width="140"
                        height="98" />
                </span>
                <jsdaddy-border-button title="Let's Talk!" [link]="contactUs" />
            </div>
        </div>
    }
}

<ng-template #showCaseRef let-showCase let-i="i" let-first="first">
    <div
        [class]="
            [
                'show-case-padding row jc-sb pos-rel',
                themeScheme.textColorStyle,
                showCase?.type,
            ].join(' ')
        ">
        @if (first && themeScheme.needShowAllCases) {
            <ng-container
                *ngTemplateOutlet="
                    showAll;
                    context: { $implicit: 'hide-mobile hide-tablet desktop-btn' }
                " />
        }
        <a
            [attr.aria-label]="showCase?.title | anchorLabel"
            [routerLink]="[caseStudies, showCase?.slug]"
            class="hide-tablet hide-mobile arrow-button">
            @if (i % 2 === 0) {
                <jsdaddy-arrow-button
                    [direction]="arrowButtonDirection"
                    [type]="themeScheme.arrowButtonType"
                    [rotateDeg]="135" />
            }
        </a>
        <div [class]="['row', i % 2 === 0 ? 'jc-fe' : '', 'full-show-case'].join(' ')">
            <div [class]="[showCase?.type, ' show-case row jc-sb'].join(' ')">
                @if (showCase?.type | showCaseIsMobile) {
                    <div class="column jc-sb">
                        <ng-container
                            *ngTemplateOutlet="titleChip; context: { $implicit: showCase }" />
                    </div>
                }
                @if (!(showCase?.type | showCaseIsMobile)) {
                    <ng-container *ngTemplateOutlet="titleChip; context: { $implicit: showCase }" />
                }
                @if (showCase?.type | showCaseIsMobile) {
                    <ng-container
                        *ngTemplateOutlet="
                            arrowBtn;
                            context: {
                                $implicit: showCase,
                                cssClass: 'hide-desktop hide-mobile',
                                rotateDeg: 135,
                            }
                        " />
                }
            </div>
            @if (showCase?.type | showCaseIsMobile) {
                <jsdaddy-image-with-frame
                    [half]="true"
                    [class]="'image-' + showCase?.type"
                    [showCase]="showCase" />
            }
        </div>
        @if (i % 2 === 1) {
            <ng-container
                *ngTemplateOutlet="
                    arrowBtn;
                    context: {
                        $implicit: showCase,
                        cssClass: 'ais-end hide-tablet hide-mobile',
                        rotateDeg: 45,
                    }
                " />
        }
    </div>
</ng-template>
<ng-template #showAll let-class>
    @if (themeScheme.needStars) {
        <jsdaddy-button
            [link]="caseStudies"
            [class]="class"
            title="Show All Cases"
            [type]="buttonType" />
    }
</ng-template>
<ng-template #arrowBtn let-showCase let-i="i" let-cssClass="cssClass" let-rotateDeg="rotateDeg">
    <a
        [routerLink]="[caseStudies, showCase?.slug]"
        [class]="[cssClass, 'arrow-button'].join(' ')"
        [attr.aria-label]="showCase?.title | anchorLabel">
        <jsdaddy-arrow-button
            [direction]="arrowButtonDirection"
            [type]="themeScheme.arrowButtonType"
            [rotateDeg]="rotateDeg" />
    </a>
</ng-template>

<ng-template #titleChip let-showCase
    ><div [class]="['column jc-sb title-chips', showCase?.type].join(' ')">
        <div class="column">
            <span [class]="[themeScheme.textColorStyle, 'h3-text'].join(' ')">
                {{ showCase?.title }}
            </span>
            <div class="chips row">
                @for (solution of showCase?.solutions; track solution.slug) {
                    <jsdaddy-chip [chip]="solution" [chipBgColor]="themeScheme.chipBgColor" />
                }
            </div>
        </div>
        @if (!(showCase?.type | showCaseIsMobile)) {
            <ng-container
                *ngTemplateOutlet="
                    arrowBtn;
                    context: {
                        $implicit: showCase,
                        cssClass: 'hide-desktop hide-mobile',
                        rotateDeg: 135,
                    }
                " />
        }
    </div>

    <div class="column description-block">
        <p [class]="['text', themeScheme.textColorStyle, showCase?.type].join(' ')">
            {{ showCase?.shortDescription }}
        </p>
        <ng-container
            *ngTemplateOutlet="
                arrowBtn;
                context: {
                    $implicit: showCase,
                    cssClass: 'hide-desktop hide-tablet',
                    rotateDeg: 135,
                }
            " />
        @if (!(showCase?.type | showCaseIsMobile)) {
            <jsdaddy-image-with-frame
                [class]="'image-' + showCase?.type"
                [half]="true"
                [showCase]="showCase" />
        }</div
></ng-template>
