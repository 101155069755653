import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'technologiesSlider',
    standalone: true,
})
export class TechnologiesSliderPipe implements PipeTransform {
    public transform(img: string): string {
        return img.replaceAll(' ', '-');
    }
}
