<div class="beard">
    @if (libraries.length) {
        @for (library of libraries; track library.firstName; let i = $index) {
            <div [class]="'plus-' + i">
                <div aria-hidden="true" class="plus" (click)="showCircleMore($event)">
                    <div class="circle-more">
                        <div class="ngx-mask">{{ library.secondName }}</div>
                        <div class="discover">
                            <a
                                [attr.aria-label]="'Github' | anchorLabel"
                                [href]="'https://github.com/JsDaddy/' + library.nameGirHubName"
                                target="_blank"
                                >Discover ></a
                            >
                        </div>
                    </div>
                </div>
                <div class="name-library">{{ library.firstName }}</div>
            </div>
        }
    }
    <div class="mustaches">
        <div class="mustache-left"></div>
        <div class="mustache-right"></div>
    </div>
    <div class="beard-opacity"></div>
</div>
<jsdaddy-grid class="elements-plus-first" [hideMobile]="true" />
<jsdaddy-grid class="elements-plus-second" [hideTablet]="true" />
<div class="js-daddys">
    <div class="js-daddys-animation"></div>
</div>
<div class="content">
    <span class="text uppercase ls-25">JS Daddy</span>
    <h1><span>High-Power</span>/><br />Business Solutions</h1>
    <p class="text">
        Achieve your goals and unlock your business potential with our expertise in CRM systems and
        Multi-Platform applications.
    </p>
    <div class="bottom-content">
        <div class="buttons row">
            <jsdaddy-button
                (click)="showContactForm()"
                [type]="buttonTypePrimrayYellow"
                title="Get Started" />

            <jsdaddy-button
                [link]="contactUsPath"
                [type]="buttonTypeGhost"
                title="Discuss My Project" />
        </div>
        <jsdaddy-upwork-svg />
    </div>
</div>
